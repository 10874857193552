import { checkUser, getToken, logOut } from "../store/auth-context";
import { router } from "../App";
import { addDays, convertToDBDate, convertToProperDate } from "./utils";

const apiURL = 'https://api-get.tork-diagnostics.com/api/';
// const apiURL = 'http://127.0.0.1:9000/index.php/api/';

const userRoles = {
    superAdmin: 'ROLE_SUPER_ADMIN',
    admin: 'ROLE_ADMIN',
    employee: 'ROLE_USER'
  };

  const dummyDiagnostics = [
  ];

  function apiRequest(method, url, data) {
    return new Promise(async (resolve, reject) => {

      const params = {
        method: method,
        headers: {
          "Content-Type": "application/json",
        },
        mode: 'cors'
      };

      if (data !== null) {
        params.body = JSON.stringify(data);
      }

      if (method === 'PATCH') {
        params.headers['Content-Type'] = "application/merge-patch+json";
      }

      const token = await getToken();

      if (token !== null) {
        params.headers.Authorization = 'Bearer ' + token;
      }

      fetch(apiURL + url, params)
        .then(async res => {
          let json = null;
          try {
            json = await res.json();
          } catch(e) {
            json = null;
          }

          return {'status': res.status, 'data': json};
        })
        .then(
          (result) => {
            if (result.status && result.status >= 300) {
              if (result.status && result.status == 401) {
                router.navigate('/logout');
                reject({'errors': ['Моля, влезте в системата.']});
              } else if (result.data && result.data.message) {
                reject({'errors': [result.data.message]});
              } else if (result.status && result.status == 404) {
                reject({'errors': ['Възникна технически проблем. Моля, опитайте отново.']});
              } else {
                reject({'errors': ['Възникна технически проблем. Моля, опитайте отново.']});
              }
            } else {
              resolve(result.data);
              //reject({'errors': ['Възникна технически проблем. Моля, опитайте отново.']});
            }
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            reject({'errors': ['Възникна технически проблем. Моля, опитайте отново.']});
          })
    });
  }
  
  function login({email, password}) {
  
      return new Promise((resolve, reject) => {
        apiRequest('POST', 'login_check', { "username":email, "password":password })
              .then(
                (result) => {
                    // resolve({token: result.token, role: userRoles.superAdmin, user: {id: 'e1', name: 'Иван Петров', company: {
                    //   id: 'c1', name: 'Tork', description: 'Самир Торк', logo: ''
                    var tmpRole = '';
                    result.user.roles.map((role, index) => tmpRole = tmpRole + `${index > 0 ? ', ' : ''}${role}`);
                    resolve({token: result.token, role: tmpRole, user: {id: result.user.id, name: result.user.name, family: result.user.family, company: {
                      id: result.practice.id, name: result.practice.name, logo: result.practice.logo
                    }}})
                },
                (error) => {
                  reject(error);
                })
      });
    };
  
    function logout() {
      return new Promise((resolve, reject) => {
          resolve(true);
        });
    };

    function getPatients(filter = {}, currentPage = 1, pageSize = 10) {
      return new Promise((resolve, reject) => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;

        apiRequest('GET', 'patients?page=' + currentPage + (Object.keys(filter).length === 0 ? '':'&search=' + encodeURIComponent(filter.name)), null)
        .then(
          (result) => {
              resolve({patients: result['hydra:member'], total: result['hydra:totalItems']})
          },
          (error) => {
            reject(error);
          });
      });
    };
  
    function getPatientDetails(patientId) {
      return new Promise((resolve, reject) => {
        apiRequest('GET', 'patients/' + patientId, null)
          .then(
            (result) => {
                resolve(result);
            },
            (error) => {
              reject(error);
            })
      });
    };
  
    function deletePatient(patientId) {
      return new Promise((resolve, reject) => {
        apiRequest('DELETE', 'patients/' + patientId, null)
          .then(
            (result) => {
                resolve(true);
            },
            (error) => {
              reject(error);
            })
      });
    };
  
    function setPatients(patientData) {
      return new Promise((resolve, reject) => {
        if (patientData.id != '') {
          apiRequest('PUT', 'patients/' + patientData.id, {
            "name": patientData.name,
            "family": patientData.family,
            "jobTitle": patientData.jobTitle,
            "sex": patientData.sex,
            "age": patientData.age
          })
          .then(
            (result) => {
                resolve(patientData);
            },
            (error) => {
              reject(error);
            })

        } else {
          apiRequest('POST', 'patients', {
            "name": patientData.name,
            "family": patientData.family,
            "jobTitle": patientData.jobTitle,
            "sex": patientData.sex,
            "age": patientData.age
          })
          .then(
            (result) => {
                resolve(result);
            },
            (error) => {
              reject(error);
            })
        };
      });
    };
  
    function getDiagnostics(filter = {}, currentPage = 1, pageSize = 10) {
      return new Promise((resolve, reject) => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;
  
        let dateAddedAfter = '';
        let dateAddedBefore = '';

        if (filter.date) {
          dateAddedAfter = filter.date.getFullYear() + '-' +
          ('00' + (filter.date.getMonth()+1)).slice(-2) + '-' +
          ('00' + filter.date.getDate()).slice(-2);

          const futureDate = addDays(filter.date, 1);
          dateAddedBefore = futureDate.getFullYear() + '-' +
          ('00' + (futureDate.getMonth()+1)).slice(-2) + '-' +
          ('00' + futureDate.getDate()).slice(-2);
        }

        apiRequest('GET', 'diagnostics?page=' + currentPage + (!filter.patientId ? '':'&patient.id=' + filter.patientId) + (!filter.date ? '':'&dateAdded[after]=' + dateAddedAfter + '&dateAdded[before]=' + dateAddedBefore), null)
        .then(
          (result) => {
              resolve({diagnostics: result['hydra:member'], total: result['hydra:totalItems']})
          },
          (error) => {
            reject(error);
          });

      });
    };
  
    function getDiagnosticDetails(diagnosticId) {
      return new Promise((resolve, reject) => {
        apiRequest('GET', 'diagnostics/' + diagnosticId + '.json', null)
          .then(
            (result) => {
                if (result.dateAdded) {
                  result.dateAdded = convertToProperDate(result.dateAdded);
                }
                if (!result.bodyScales.fatEval) {
                  result.bodyScales.fatEval = 0;
                }
                if (!result.bodyScales.hydrationEval) {
                  result.bodyScales.hydrationEval = 0;
                }
                if (!result.bodyScales.musclesEval) {
                  result.bodyScales.musclesEval = 0;
                }
                if (!result.thermography) {
                  result.thermography = {
                    spine: {note: '', degreesMin: null, degreesMax: null, image: '', imageNew: 0},
                    neck: {note: '', degreesMin: null, degreesMax: null, image: '', imageNew: 0},
                    thoracic: {note: '', degreesMin: null, degreesMax: null, image: '', imageNew: 0},
                    lumbar: {note: '', degreesMin: null, degreesMax: null, image: '', imageNew: 0},
                    sacral: {note: '', degreesMin: null, degreesMax: null, image: '', imageNew: 0},
                    rArmLat: {note: '', degreesMin: null, degreesMax: null, image: '', imageNew: 0},
                    rArmMed: {note: '', degreesMin: null, degreesMax: null, image: '', imageNew: 0},
                    lArmLat: {note: '', degreesMin: null, degreesMax: null, image: '', imageNew: 0},
                    lArmMed: {note: '', degreesMin: null, degreesMax: null, image: '', imageNew: 0},
                    elbowLat: {note: '', degreesMin: null, degreesMax: null, image: '', imageNew: 0},
                    elbowMed: {note: '', degreesMin: null, degreesMax: null, image: '', imageNew: 0},
                    wristSup: {note: '', degreesMin: null, degreesMax: null, image: '', imageNew: 0},
                    wristPro: {note: '', degreesMin: null, degreesMax: null, image: '', imageNew: 0},
                    shoulderFront: {note: '', degreesMin: null, degreesMax: null, image: '', imageNew: 0},
                    shoulderBack: {note: '', degreesMin: null, degreesMax: null, image: '', imageNew: 0},
                    rLegFront: {note: '', degreesMin: null, degreesMax: null, image: '', imageNew: 0},
                    lLegFront: {note: '', degreesMin: null, degreesMax: null, image: '', imageNew: 0},
                    kneeFront: {note: '', degreesMin: null, degreesMax: null, image: '', imageNew: 0},
                    hip: {note: '', degreesMin: null, degreesMax: null, image: '', imageNew: 0},
                    ankle: {note: '', degreesMin: null, degreesMax: null, image: '', imageNew: 0},
                    rLegBack: {note: '', degreesMin: null, degreesMax: null, image: '', imageNew: 0},
                    lLegBack: {note: '', degreesMin: null, degreesMax: null, image: '', imageNew: 0},
                    kneeBack: {note: '', degreesMin: null, degreesMax: null, image: '', imageNew: 0},
                    achilles: {note: '', degreesMin: null, degreesMax: null, image: '', imageNew: 0},
                  };
                }
                resolve(result);
            },
            (error) => {
              reject(error);
            })
      });
    };
  
    function deleteDiagnostic(diagnosticId) {
      return new Promise((resolve, reject) => {
        apiRequest('DELETE', 'diagnostics/' + diagnosticId, null)
          .then(
            (result) => {
                resolve(true);
            },
            (error) => {
              reject(error);
            })
      });
    };
  
    function setDiagnostic(diagnosticData) {
      return new Promise((resolve, reject) => {
        if (diagnosticData.id != '') {
          apiRequest('PATCH', 'diagnostics/' + diagnosticData.id, diagnosticData)
          .then(
            (result) => {
                resolve(diagnosticData)
            },
            (error) => {
              reject(error);
            })

        } else {
          delete diagnosticData.id;
          apiRequest('POST', 'diagnostics', diagnosticData)
          .then(
            (result) => {
                resolve(result)
            },
            (error) => {
              reject(error);
            })
        };
      });
    };
  
    function getUsers(filter = {}, currentPage = 1, pageSize = 10) {
      return new Promise((resolve, reject) => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;

        apiRequest('GET', 'users?page=' + currentPage + (Object.keys(filter).length === 0 ? '':'&roles=' + filter.role + '&search=' + encodeURIComponent(filter.name)), null)
        .then(
          (result) => {
            var tmpUsers = [];
            result['hydra:member'].map(member => {
              var memberRoles = '';
              member.roles.map((role, index) => memberRoles = memberRoles + `${index > 0 ? ', ' : ''}${role}`);
              tmpUsers.push({id: member.id, email:member.email, password: member.plainPassword, name: member.name, family:member.family, role: memberRoles, company: member.practice.name})
            });
            resolve({users: tmpUsers, total: result['hydra:totalItems']})
          },
          (error) => {
            reject(error);
          });
      });
    };
  
    function getUserDetails(userId) {
      return new Promise((resolve, reject) => {
        apiRequest('GET', 'users/' + userId, null)
          .then(
            (result) => {
                var tmpRole = '';
                result.roles.map((role, index) => tmpRole = tmpRole + `${index > 0 ? ', ' : ''}${role}`);
                result.roles = tmpRole;
                resolve({id: result.id, email: result.email, name: result.name, family: result.family, password: result.plainPassword, role: result.roles, company: result.practice, dateAdded: result.dateAdded, lastModified: result.lastModified});
            },
            (error) => {
              reject(error);
            })
      });
    };
  
    function deleteUser(userId) {
      return new Promise((resolve, reject) => {
        apiRequest('DELETE', 'users/' + userId, null)
        .then(
          (result) => {
              resolve(true);
          },
          (error) => {
            reject(error);
          })
      });
    };
  
    function setUsers(userData) {
      return new Promise((resolve, reject) => {
        if (userData.id != '') {
          if (checkUser([userRoles.superAdmin]) === 'ROLE_SUPER_ADMIN') {
          apiRequest('PUT', 'users/' + userData.id, {
            "email": userData.email,
            "roles": [userData.role],
            "plainPassword": userData.password,
            "practice": userData.company['@id'],
            "name": userData.name,
            "family": userData.family,
          })
          .then(
            (result) => {
                resolve(userData);
            },
            (error) => {
              reject(error);
            })

        } else {

          apiRequest('PUT', 'users/' + userData.id, {
            "email": userData.email,
            "roles": [userData.role],
            "plainPassword": userData.password,
            "name": userData.name,
            "family": userData.family,
          })
          .then(
            (result) => {
                resolve(userData);
            },
            (error) => {
              reject(error);
            })

        }
      
      } else {
        if (checkUser([userRoles.superAdmin]) === 'ROLE_SUPER_ADMIN') {
          apiRequest('POST', 'users', {
            "email": userData.email,
            "roles": [userData.role],
            "plainPassword": userData.password,
            "practice": userData.company['@id'],
            "name": userData.name,
            "family": userData.family,
          })
          .then(
            (result) => {
                resolve(result);
            },
            (error) => {
              reject(error);
            })

          } else {
            apiRequest('POST', 'users', {
              "email": userData.email,
              "roles": [userData.role],
              "plainPassword": userData.password,
              "name": userData.name,
              "family": userData.family,
            })
            .then(
              (result) => {
                  resolve(result);
              },
              (error) => {
                reject(error);
              })

        };
      }});
    };
  
    function getCompanies(filter = {}, currentPage = 1, pageSize = 10) {
      return new Promise((resolve, reject) => {
        const firstPageIndex = (currentPage - 1) * pageSize;
        const lastPageIndex = firstPageIndex + pageSize;

        apiRequest('GET', 'practices?page=' + currentPage + (Object.keys(filter).length === 0 ? '':'&name=' + encodeURIComponent(filter.name)), null)
        .then(
          (result) => {
              resolve({companies: result['hydra:member'], total: result['hydra:totalItems']})
          },
          (error) => {
            reject(error);
          });
      });
    };
  
    function getCompanyDetails(companyId) {
      return new Promise((resolve, reject) => {
        apiRequest('GET', 'practices/' + companyId, null)
          .then(
            (result) => {
                resolve(result);
            },
            (error) => {
              reject(error);
            })
      });
    };
  
    function deleteCompany(companyId) {
      return new Promise((resolve, reject) => {
        apiRequest('DELETE', 'practices/' + companyId, null)
          .then(
            (result) => {
                resolve(true);
            },
            (error) => {
              reject(error);
            })
      });
    };
  
    function setCompanies(companyData) {
      return new Promise((resolve, reject) => {
        if (companyData.id != '') {
          apiRequest('PUT', 'practices/' + companyData.id, {
            "name": companyData.name,
            "description": companyData.description,
            "logo": companyData.logo,
          })
          .then(
            (result) => {
                resolve(companyData)
            },
            (error) => {
              reject(error);
            })

        } else {
          apiRequest('POST', 'practices', {
            "name": companyData.name,
            "description": companyData.description,
            "logo": companyData.logo,
          })
          .then(
            (result) => {
                resolve(result)
            },
            (error) => {
              reject(error);
            })
        };
      });
    };
  
    function setProfilePassword(password) {
      return new Promise((resolve, reject) => {
        resolve(true);
      });
    };
  
    export {userRoles};
  
    export function getUserRoleName(role) {
      switch (`${role}`) {
        case `${userRoles.superAdmin}`: 
          return 'супер администратор';
        case `${userRoles.admin}`: 
          return 'администратор';
        case `${userRoles.employee}`: 
          return 'служител';
        default:
          //
      }
    }
  
    export const Api = {
      login: login,
      logout: logout,
      getPatients: getPatients,
      getPatientDetails: getPatientDetails,
      setPatients: setPatients,
      deletePatient: deletePatient,
      getDiagnostics: getDiagnostics,
      getDiagnosticDetails: getDiagnosticDetails,
      deleteDiagnostic: deleteDiagnostic,
      setDiagnostic: setDiagnostic,
      getUsers: getUsers,
      getUserDetails: getUserDetails,
      setUsers: setUsers,
      deleteUser: deleteUser,
      setProfilePassword: setProfilePassword,
      getCompanies: getCompanies,
      getCompanyDetails: getCompanyDetails,
      deleteCompany: deleteCompany,
      setCompanies: setCompanies
    };