import React from 'react';
import PersonalCard from "../../components/UI/PersonalCard";
import ThermographyCard from "../../components/UI/ThermographyCard";

import FeetModel from "../../models/Feet";
import ElasticityModel from "../../models/Elasticity";
import SpineModel from '../../models/Spine';
import CurvModel from '../../models/Curv';
import CervicalModel from '../../models/Cervical';
import LumbarModel from '../../models/Lumbar';
import {leftBack, leftFront, rightBack, rightFront} from "../../constants/Sensitivity";
import {
    convertToProperDate,
    cvtDateToString,
    goniometryEvaluation,
    goniometryLeftRight,
    goniometryLeftRightClassName,
    goniometryLeftRightSingleRef,
    goniometryLeftRightSingleRefClassName,
    musclesEvaluation,
    musclesEvaluationShortening,
    musclesEvaluationLatRot,
    musclesEvaluationLatRotShortening,
    musclesEvaluationSingle,
    musclesEvaluationSingleShortening,
    musclesEvaluationSingleWeakness,
    musclesEvaluationLatRotWeakness
} from '../../utils/utils';

import neck from './Images/neck.jpg';
import femur from './Images/femur.jpg';
import tibia from './Images/tibia.jpg';
import humerus from './Images/humerus.jpg';
import radius from './Images/radius.jpg';
import hip from './Images/hip.jpg';
import shank from './Images/shank.jpg';
import chest from './Images/chest.jpg';
import abdomen from './Images/abdomen.jpg';
import shoulders from './Images/shoulders.jpg';
import biceps from './Images/biceps.jpg';
import forearm from './Images/forearm.jpg';
import neck_measurement from './Images/neck_measurement.jpg';
import musclesFront from './Images/muscles_front.jpg';
import musclesBack from './Images/muscles_back.jpg';
import varusNormal from './Images/varus_normal.jpg';
import varusBow from './Images/varus_bow.jpg';
import varusKnock from './Images/varus_knock.jpg';
import varusLBRK from './Images/varus_lbrk.jpg';
import varusLKRB from './Images/varus_lkrb.jpg';
import supProNeutral from './Images/neutral.jpg';
import supProSupination from './Images/supination.jpg';
import supProOverSupination from './Images/oversupination.jpg';
import supProPronation from './Images/pronation.jpg';
import supProOverPronation from './Images/overpronation.jpg';
import spineHLLKLC from './Images/spine_hyper_lord_lumb_kyph_lord_cerv.jpg';
import spineHLLK from './Images/spine_hyper_lord_lumb_kyph.jpg';
import spineHLL from './Images/spine_hyper_lord_lumb.jpg';
import spineK from './Images/spine_kyph.jpg';
import spineLCFLL from './Images/spine_lord_cerv_flatback_lord_lumb.jpg';
import spineLCHLL from './Images/spine_lord_cerv_hyper_lord_lumb.jpg';
import spineLCK from './Images/spine_lord_cerv_kyph.jpg';
import spineLCLL from './Images/spine_lord_cerv_lord_lumb.jpg';
import spineLC from './Images/spine_lord_cerv.jpg';
import spineLLKLC from './Images/spine_lord_lumb_kyph_lord_cerv.jpg';
import spineLLK from './Images/spine_lord_lumb_kyph.jpg';
import spineLL from './Images/spine_lord_lumb.jpg';
import spineN from './Images/spine_norm.jpg';
import spineSLLTL from './Images/spine_scol_lumb_left_thor_left.jpg';
import spineSLLTR from './Images/spine_scol_lumb_left_thor_right.jpg';
import spineSLL from './Images/spine_scol_lumb_left.jpg';
import spineSLRTL from './Images/spine_scol_lumb_right_thor_left.jpg';
import spineSLRTR from './Images/spine_scol_lumb_right_thor_right.jpg';
import spineSLR from './Images/spine_scol_lumb_right.jpg';
import spineSTL from './Images/spine_scol_thor_left.jpg';
import spineSTR from './Images/spine_scol_thor_right.jpg';
import jointsAnkle from './Images/joints_ankle.jpg';
import jointsElbow from './Images/joints_elbow.jpg';
import jointsElbow1 from './Images/joints_elbow1.jpg';
import jointsHip from './Images/joints_hip.jpg';
import jointsHip1 from './Images/joints_hip1.jpg';
import jointsHip2 from './Images/joints_hip2.jpg';
import jointsKnee from './Images/joints_knee.jpg';
import jointsShoulder from './Images/joints_shoulder.jpg';
import jointsShoulder1 from './Images/joints_shoulder1.jpg';
import jointsShoulder2 from './Images/joints_shoulder2.jpg';
import jointsWrist from './Images/joints_wrist.jpg';
import jointsWrist1 from './Images/joints_wrist1.jpg';
import fat from './Images/fat.png';
import muscles from './Images/muscles.png';
import hydration from './Images/hydration.png';
import bones from './Images/bones.png';
import activeMetabolism from './Images/active_metabolism.png';
import basalMetabolism from './Images/basal_metabolism.png';
import pin from './Images/pin.png';
import LevaturModel from "../../models/Levatur";

const spineImages = {
    '1': spineN,
    '2': spineSLL,
    '3': spineSLR,
    '4': spineSTL,
    '5': spineSTR,
    '6': spineSLRTL,
    '7': spineSLLTR,
    '8': spineSLLTL,
    '9': spineSLRTR,
    '10': spineK,
    '11': spineLL,
    '12': spineLLK,
    '13': spineLLKLC,
    '14': spineHLL,
    '15': spineHLLK,
    '16': spineHLLKLC,
    '17': spineLC,
    '18': spineLCK,
    '19': spineLCHLL,
    '20': spineLCLL,
    '21': spineLCFLL,
}

const DiagnosticsSheetData = React.forwardRef((props, ref) => {

    function musclesEvaluationCentimetrics(centimetricsLeftValue, centimetricsRightValue, jointLeftValue, jointRightValue, goniometryType, min, max) {
        if (centimetricsLeftValue == centimetricsRightValue) {
            return musclesEvaluation('', goniometryType, min, max, jointLeftValue, jointRightValue);
        } else {
            if (centimetricsLeftValue < centimetricsRightValue) {
                switch (musclesEvaluation('', goniometryType, min, max, jointLeftValue, jointRightValue)) {
                    case '':
                    case 'в норма':
                    case 'слабост отляво':
                        return 'слабост отляво';
                        break;
                    case 'слабост':
                        return 'слабост повече отляво';
                        break;
                    case 'слабост отдясно':
                    case 'слабост повече отдясно':
                    case 'лека слабост':
                        return 'слабост';
                        break;
                    case 'слабост повече отляво':
                        return 'слабост повече отляво';
                        break;
                    case 'скъсена мускулатура':
                        return 'скъсена мускулатура, слабост отляво';
                        break;
                    case 'скъсяване отдясно':
                        return 'скъсяване отдясно, слабост отляво';
                        break;
                    case 'скъсяване отляво':
                        return 'скъсяване отляво, слабост отляво';
                        break;
                    case 'скъсяване отдясно, слабост отляво':
                        return 'скъсяване отляво, слабост отляво';
                        break;
                    case 'скъсяване отляво, слабост отдясно':
                        return 'скъсяване отляво, слабост';
                        break;
                    case 'скъсяване повече отляво':
                        return 'скъсяване повече отляво, слабост отляво';
                        break;
                     case 'скъсяване повече отдясно':
                        return 'скъсяване повече отдясно, слабост отляво';
                        break;
                }
            } else {
                switch (musclesEvaluation('', goniometryType, min, max, jointLeftValue, jointRightValue)) {
                    case '':
                    case 'в норма':
                    case 'слабост отдясно':
                        return 'слабост отдясно';
                        break;
                    case 'слабост':
                        return 'слабост повече отдясно';
                        break;
                    case 'слабост отляво':
                    case 'слабост повече отляво':
                    case 'лека слабост':
                        return 'слабост';
                        break;
                    case 'слабост повече отдясно':
                        return 'слабост повече отдясно';
                        break;
                    case 'скъсена мускулатура':
                        return 'скъсена мускулатура, слабост отдясно';
                        break;
                    case 'скъсяване отдясно':
                        return 'скъсяване отдясно, слабост отдясно';
                        break;
                    case 'скъсяване отляво':
                        return 'скъсяване отляво, слабост отдясно';
                        break;
                    case 'скъсяване отдясно, слабост отляво':
                        return 'скъсяване отдясно, слабост';
                        break;
                    case 'скъсяване отляво, слабост отдясно':
                        return 'скъсяване отляво, слабост отдясно';
                        break;
                    case 'скъсяване повече отляво':
                        return 'скъсяване повече отляво, слабост отдясно';
                        break;
                     case 'скъсяване повече отдясно':
                        return 'скъсяване повече отдясно, слабост отдясно';
                        break;
            };
        }
        }
    };
    function musclesEvaluationCentimetricsAchiles(centimetricsLeftValue, centimetricsRightValue, jointLeftValue, jointRightValue, goniometryType, min, max) {
        if (centimetricsLeftValue == centimetricsRightValue) {
            return musclesEvaluation('', goniometryType, min, max, jointLeftValue, jointRightValue);
        } else {
            if (centimetricsLeftValue < centimetricsRightValue) {
                switch (musclesEvaluation('', goniometryType, min, max, jointLeftValue, jointRightValue)) {
                    case '':
                    case 'в норма':
                    case 'слабост отдясно':
                    case 'слабост':
                    case 'слабост отляво':
                    case 'лека слабост повече отляво':
                    case 'слабост повече отляво':
                    case 'лека слабост':
                    case 'лека слабост повече отдясно':
                    case 'слабост повече отдясно':
                        return 'в норма';
                        break;
                    case 'скъсена мускулатура':
                        return 'скъсена мускулатура';
                        break;
                    case 'скъсяване отдясно':
                        return 'скъсяване отдясно';
                        break;
                    case 'скъсяване отляво':
                        return 'скъсяване отляво';
                        break;
                    case 'скъсяване отдясно, слабост отляво':
                        return 'скъсяване отляво';
                        break;
                    case 'скъсяване отляво, слабост отдясно':
                        return 'скъсяване отляво';
                        break;
                    case 'скъсяване повече отляво':
                        return 'скъсяване повече отляво';
                        break;
                     case 'скъсяване повече отдясно':
                        return 'скъсяване повече отдясно';
                        break;
                }
            } else {
                switch (musclesEvaluation('', goniometryType, min, max, jointLeftValue, jointRightValue)) {
                    case '':
                    case 'в норма':
                    case 'слабост отдясно':
                    case 'слабост':
                    case 'слабост отляво':
                    case 'лека слабост повече отляво':
                    case 'слабост повече отляво':
                    case 'лека слабост':
                    case 'лека слабост повече отдясно':
                    case 'слабост повече отдясно':
                        return 'в норма';
                        break;
                    case 'скъсена мускулатура':
                        return 'скъсена мускулатура';
                        break;
                    case 'скъсяване отдясно':
                        return 'скъсяване отдясно';
                        break;
                    case 'скъсяване отляво':
                        return 'скъсяване отляво';
                        break;
                    case 'скъсяване отдясно, слабост отляво':
                        return 'скъсяване отдясно';
                        break;
                    case 'скъсяване отляво, слабост отдясно':
                        return 'скъсяване отляво';
                        break;
                    case 'скъсяване повече отляво':
                        return 'скъсяване повече отляво';
                        break;
                     case 'скъсяване повече отдясно':
                        return 'скъсяване повече отдясно';
                        break;
            };
        }
        }
    };

    function bodyScalesFat(fat, age, gender) {
        let minValue = null;
        let maxValue = null;
        let left = '';
        let segmentMin = null;
        let segmentMax = null;
        let segments = [];
        if (fat) {
            if (age <= 30) {
                if (gender === 'мъж') {
                    segments = [{min: 4, max: 11}, {min: 11.1, max: 15.5}, {min: 15.6, max: 20}, {min: 20.1, max: 24.5}, {min: 24.6, max: 60}];
                } else {
                    segments = [{min: 4, max: 16}, {min: 16.1, max: 20.5}, {min: 20.6, max: 25}, {min: 25.1, max: 30.5}, {min: 30.6, max: 60}];
                }
            } else {
                if (gender === 'мъж') {
                    segments = [{min: 4, max: 15}, {min: 15.1, max: 19.5}, {min: 19.6, max: 24}, {min: 24.1, max: 28.5}, {min: 28.6, max: 60}];
                } else {
                    segments = [{min: 4, max: 20}, {min: 20.1, max: 25}, {min: 25.1, max: 30}, {min: 30.1, max: 35}, {min: 35.1, max: 60}];
                }
            }
            for (let i = 0; i < segments.length; i++) {
                if (fat >= segments[i].min && fat <= segments[i].max) {
                    minValue = segments[i].min;
                    maxValue = segments[i].max;
                    segmentMin = (i + 1)*20 - 20;
                    segmentMax = (i + 1)*20;
                    left = (segmentMin + (((fat - minValue)*(segmentMax - segmentMin))/(maxValue - minValue))).toString();
                    return <div style={{position:'absolute', top:'-26px', left: "calc("+left+"% - 16px)"}}><img src={pin} /></div>
                }
            }

            if (fat < 4) {
                return <div style={{position:'absolute', top:'-26px', left: "calc(0% - 16px)"}}><img src={pin} /></div>
            } else {
                return <div style={{position:'absolute', top:'-26px', left: "calc(100% - 16px)"}}><img src={pin} /></div>
            }

    
        }
    };

    function bodyScalesHydration(hydration, age, gender) {
        let minValue = null;
        let maxValue = null;
        let left = '';
        let segmentMin = null;
        let segmentMax = null;
        let segments = [];
        if (hydration) {
            if (age <= 30) {
                if (gender === 'мъж') {
                    segments = [{min: 27.5, max: 51.8}, {min: 51.9, max: 54.9}, {min: 55, max: 58}, {min: 58.1, max: 61.1}, {min: 61.2, max: 66}];
                } else {
                    segments = [{min: 27.5, max: 47.7}, {min: 47.8, max: 51.5}, {min: 51.6, max: 54.6}, {min: 54.7, max: 57.7}, {min: 57.8, max: 66}];
                }
            } else {
                if (gender === 'мъж') {
                    segments = [{min: 27.5, max: 49.1}, {min: 49.2, max: 52.2}, {min: 52.3, max: 55.2}, {min: 55.3, max: 58.3}, {min: 58.4, max: 66}];
                } else {
                    segments = [{min: 27.5, max: 44.6}, {min: 44.7, max: 48}, {min: 48.1, max: 51.5}, {min: 51.6, max: 54.9}, {min: 55, max: 66}];
                }
            }
            for (let i = 0; i < segments.length; i++) {
                if (hydration >= segments[i].min && hydration <= segments[i].max) {
                    minValue = segments[i].min;
                    maxValue = segments[i].max;
                    segmentMin = (i + 1)*20 - 20;
                    segmentMax = (i + 1)*20;
                    left = (100 - (segmentMin + (((hydration - minValue)*(segmentMax - segmentMin))/(maxValue - minValue)))).toString();
                    return <div style={{position:'absolute', top:'-26px', left: "calc("+left+"% - 16px)"}}><img src={pin} /></div>
                }
            }

            if (hydration > 66) {
                return <div style={{position:'absolute', top:'-26px', left: "calc(0% - 16px)"}}><img src={pin} /></div>
            }
            if (hydration < 27.5) {
                return <div style={{position:'absolute', top:'-26px', left: "calc(100% - 16px)"}}><img src={pin} /></div>
            }

    
        }
    };

    function bodyScalesMuscles(muscles, age, gender) {
        let minValue = null;
        let maxValue = null;
        let left = '';
        let segmentMin = null;
        let segmentMax = null;
        let segments = [];
        if (muscles) {
            if (age <= 39) {
              if (gender === 'мъж') {
                segments = [{ min: 20, max: 31 }, { min: 32, max: 48 }, { min: 49, max: 72 }, { min: 73, max: 86 }];
              } else {
                segments = [{ min: 20, max: 28 }, { min: 29, max: 45 }, { min: 46, max: 69 }, { min: 70, max: 83 }];
              }
            } else if (age > 39 && age <= 49) {
              if (gender === 'мъж') {
                segments = [{ min: 20, max: 31 }, { min: 32, max: 43 }, { min: 44, max: 69 }, { min: 70, max: 83 }];
              } else {
                segments = [{ min: 20, max: 31 }, { min: 32, max: 43 }, { min: 44, max: 68 }, { min: 69, max: 80 }];
              }
            } else if (age > 49 && age <= 59) {
              if (gender === 'мъж') {
                segments = [{ min: 20, max: 27 }, { min: 28, max: 39 }, { min: 40, max: 63 }, { min: 64, max: 80 }];
              } else {
                segments = [{ min: 20, max: 27 }, { min: 28, max: 39 }, { min: 40, max: 66 }, { min: 67, max: 80 }];
              }
            } else if (age > 59 && age <= 69) {
              if (gender === 'мъж') {
                segments = [{ min: 15, max: 23 }, { min: 24, max: 35 }, { min: 36, max: 55 }, { min: 56, max: 80 }];
              } else {
                segments = [{ min: 15, max: 23 }, { min: 24, max: 35 }, { min: 36, max: 55 }, { min: 56, max: 80 }];
              }
            } else if (age > 69) {
              if (gender === 'мъж') {
                segments = [{ min: 15, max: 21 }, { min: 22, max: 30 }, { min: 31, max: 45 }, { min: 46, max: 80 }];
              } else {
                segments = [{ min: 15, max: 21 }, { min: 22, max: 30 }, { min: 31, max: 45 }, { min: 46, max: 80 }];
              }
            }

            for (let i = 0; i < segments.length; i++) {
                if (muscles >= segments[i].min && muscles <= segments[i].max) {
                    minValue = segments[i].min;
                    maxValue = segments[i].max;
                    segmentMin = (i + 1)*25 - 25;
                    segmentMax = (i + 1)*25;
                    left = (100 - (segmentMin + (((muscles - minValue)*(segmentMax - segmentMin))/(maxValue - minValue)))).toString();
                    return <div style={{position:'absolute', top:'-26px', left: "calc("+left+"% - 16px)"}}><img src={pin} /></div>
                }
            }

            if (muscles > 80) {
                return <div style={{position:'absolute', top:'-26px', left: "calc(0% - 16px)"}}><img src={pin} /></div>
            }
            if (muscles < 15) {
                return <div style={{position:'absolute', top:'-26px', left: "calc(100% - 16px)"}}><img src={pin} /></div>
            }

        }
    };

    // const marginTop = '10mm';
    // const marginRight = '6mm';
    // const marginBottom = '10mm';
    // const marginLeft = '6mm';

    // const getPageMargins = () => {
    //     return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important; }`;
    //   };

    return (
        <section className='lists' ref={ref}>
            {/* <style>{getPageMargins()}</style> */}
            <div className='centered-print'><img src={props.data.logo} alt="logo" className='logo-img' /></div>
            <div className="card-error">
                <p>Лица, които не са обучени по методиката на физиотерапевт Самир Торк, нямат право и нужната компетентност за разчитането на диагностиката и провеждане на рехабилитация въз основа на изготвената документация.</p>
                <p>Всякакъв опит за разчитане на диагностиката без нужното обучение от физиотерапевт Самир Торк носи риск за здравето на пациента.</p>
                <p>За да проверите дали Вашият специалист-терапевт е преминал нужното обучение от физиотерапевт Самир Торк, моля да се свържете на посочения тел.: 0988/76 99 00 или e-mail: tork.varna@gmail.com</p>
                <p>www.torks.bg</p>
            </div>
            <h1 className='colored'>Физикална и обща диагностика</h1>
            <p className='dates centered-print' style={{margin: '-1.5em auto 2.5em'}}>Добавена на: {cvtDateToString(props.data.values.dateAdded)} г. Последно редактирана на: {convertToProperDate(props.data.values.lastModified)} г.</p>
            <PersonalCard data={{id: props.data.values.patient.id, name: props.data.values.patient.name, family: props.data.values.patient.family, age: props.data.values.patient.age, jobTitle: props.data.values.patient.jobTitle, sex: props.data.values.patient.sex}} />
            <h2>Витални показатели</h2>
            <div className='vital'>
                <div className='multi-inp wider top'>
                    <div className='left third'>
                        <p><label>Ръст:</label>{props.data.values.vitals.height && <>{props.data.values.vitals.height} см</>}</p>
                        <p><label>Тегло:</label>{props.data.values.vitals.weight && <>{props.data.values.vitals.weight} кг</>}</p>
                    </div>
                    <div className='left third'>
                        <p><label>Кръвно налягане:</label>{props.data.values.vitals.bloodPressure}</p>
                        <p><label>Пулс:</label>{props.data.values.vitals.pulse}</p>
                    </div>
                    <div className='left third'>
                        <p><label>Сатурация:</label>{props.data.values.vitals.saturation && <>{props.data.values.vitals.saturation}%</>}</p>
                    </div>
                    <div className='left'>
                        <p><label>Анамнеза:</label>{props.data.values.vitals.medHistory}</p>
                    </div>
                </div>
            </div>
            <div className="page-break" />
            <h2>Измерване с калипер</h2>
            <table className='simple-tbl'>
                <thead>
                    <tr>
                        <th>Подскапула</th>
                        <th>Трицепс</th>
                        <th>Бицепс</th>
                        <th>Надиалична</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{props.data.values.caliper.subscapula && <>{props.data.values.caliper.subscapula} мм (10.6 мм)</>}</td>
                        <td>{props.data.values.caliper.triceps && <>{props.data.values.caliper.triceps} мм (12.4 мм)</>}</td>
                        <td>{props.data.values.caliper.biceps && <>{props.data.values.caliper.biceps} мм (5.6 мм)</>}</td>
                        <td>{props.data.values.caliper.nadialic && <>{props.data.values.caliper.nadialic} мм (12 мм)</>}</td>
                    </tr>
                </tbody>
            </table>
            <h2>Лабораторни кръвни изследвания</h2>
            <div className='left'>
                {props.data.values.blood.map((test) => (
                    <p key={test.label}>
                        <label>{test.label}:</label>
                        {test.result && <span className={test.conclusion === 'def' ? 'alert-def' : (test.conclusion === 'close-def' ? 'alert-warn' : (test.conclusion === 'ab' ? 'alert-ab' : 'alert-ok'))}>{test.result} {test.units}</span>}
                    </p>
                ))}
            </div>
            <h2>Метаболизъм</h2>
            <div className='flex-left'>
                <div className='multi-inp'><img src={fat} className='subsection-icon' /><label>Мазнини:</label>{props.data.values.bodyScales.fat && <>{props.data.values.bodyScales.fat}%</>}</div>
                <div className='scale'>
                    {/* {bodyScalesFat(props.data.values.bodyScales.fat, props.data.values.patient.age, props.data.values.patient.sex)} */}
                    <div style={{position:'absolute', top:'-26px', left: "calc("+props.data.values.bodyScales.fatEval+"% - 16px)"}}><img src={pin} /></div>
                    <div className='segment blue'>&nbsp;</div><div className='segment green'>&nbsp;</div><div className='segment red'>&nbsp;</div>
                </div>
                <div className='multi-inp'><img src={hydration} className='subsection-icon' /><label>Хидратация:</label>{props.data.values.bodyScales.hydration && <>{props.data.values.bodyScales.hydration}%</>}</div>
                <div className='scale'>
                    {/* {bodyScalesHydration(props.data.values.bodyScales.hydration, props.data.values.patient.age, props.data.values.patient.sex)} */}
                    <div style={{position:'absolute', top:'-26px', left: "calc("+props.data.values.bodyScales.hydrationEval+"% - 16px)"}}><img src={pin} /></div>
                    <div className='segment blue'>&nbsp;</div><div className='segment green'>&nbsp;</div><div className='segment red'>&nbsp;</div>
                </div>
                <div className='multi-inp'><img src={muscles} className='subsection-icon' /><label>Мускули:</label>{props.data.values.bodyScales.muscles && <>{props.data.values.bodyScales.muscles}%</>}</div>
                    <div className='scale'>
                    {/* {bodyScalesMuscles(props.data.values.bodyScales.muscles, props.data.values.patient.age, props.data.values.patient.sex)} */}
                    <div style={{position:'absolute', top:'-26px', left: "calc("+props.data.values.bodyScales.musclesEval+"% - 16px)"}}><img src={pin} /></div>
                    <div className='segment blue'>&nbsp;</div><div className='segment green'>&nbsp;</div><div className='segment red'>&nbsp;</div>
                </div>
                <div className='multi-inp'><img src={bones} className='subsection-icon' /><label>Костна маса:</label>{props.data.values.bodyScales.bones && <>{props.data.values.bodyScales.bones} кг</>}</div>
                <div className='multi-inp'><img src={basalMetabolism} className='subsection-icon' /><label>Базален метаболизъм:</label>{props.data.values.bodyScales.basalMetabolism && <>{props.data.values.bodyScales.basalMetabolism} Kcal</>}</div>
                <div className='multi-inp'><img src={activeMetabolism} className='subsection-icon' /><label>Активен метаболизъм:</label>{props.data.values.bodyScales.activeMetabolism && <>{props.data.values.bodyScales.activeMetabolism} Kcal</>}</div>
            </div>
            <div className="page-break" />
            <h2>Диагностика на ходилата</h2>
            {props.data.values.feet.image && <div className='centered-print'><img src={props.data.values.feet.image} className='feet-img' alt="feet" /></div>}
            {/* <div className="page-break" /> */}
            <div className='card-imgs'>
                <div className='feet left-col'>
                    <h3>Ляво ходило:</h3>
                    <img src={supProSupination} className={props.data.values.feet.supPro.left === 'supination' ? 'outlined-img-alert' : ''} /><img src={supProOverSupination} className={props.data.values.feet.supPro.left === 'overSupination' ? 'outlined-img-alert' : ''} /><img src={supProOverPronation} className={props.data.values.feet.supPro.left === 'overPronation' ? 'outlined-img-alert' : ''}/><img src={supProPronation} className={props.data.values.feet.supPro.left === 'pronation' ? 'outlined-img-alert' : ''} /><img src={supProNeutral} className={props.data.values.feet.supPro.left === 'neutral' ? 'outlined-img-ok' : ''} />
                </div>
                <div className='feet'>
                    <h3>Дясно ходило:</h3>
                    <img src={supProSupination} className={props.data.values.feet.supPro.right === 'supination' ? 'outlined-img-alert' : ''} /><img src={supProOverSupination} className={props.data.values.feet.supPro.right === 'overSupination' ? 'outlined-img-alert' : ''} /><img src={supProOverPronation} className={props.data.values.feet.supPro.right === 'overPronation' ? 'outlined-img-alert' : ''} /><img src={supProPronation} className={props.data.values.feet.supPro.right === 'pronation' ? 'outlined-img-alert' : ''} /><img src={supProNeutral} className={props.data.values.feet.supPro.right === 'neutral' ? 'outlined-img-ok' : ''} />
                </div>
            </div>
            {/* <div className="page-break" /> */}
            <h3>Варусна-валгусна деформация</h3>
            <div className='card-imgs'>
                <div className='varus'>
                    <img src={varusNormal} className={props.data.values.feet.varus === 'normal' ? 'outlined-img-ok' : ''} /><img src={varusBow} className={props.data.values.feet.varus === 'bow' ? 'outlined-img-alert' : ''} /><img src={varusKnock} className={props.data.values.feet.varus === 'knock' ? 'outlined-img-alert' : ''} /><img src={varusLBRK} className={props.data.values.feet.varus === 'leftBowRightKnock' ? 'outlined-img-alert' : ''} /><img src={varusLKRB} className={props.data.values.feet.varus === 'leftKnockRightBow' ? 'outlined-img-alert' : ''} />
                </div>
            </div>
            <div className='left feet-section'>
                <p><label>Ляво ходило:</label> {props.data.values.feet.left.map((feet, index) => 
                    <span key={index}>
                        { (index ? ', ' : '') + FeetModel.getFeetString(feet) }
                    </span>
                )}</p>
                <p><label>Дясно ходило:</label> {props.data.values.feet.right.map((feet, index) => 
                    <span key={index}>
                        { (index ? ', ' : '') + FeetModel.getFeetString(feet) }
                    </span>
                )}</p>
                <p><label>Сводове:</label>
                    {props.data.values.feet.arch.left &&
                    <span className={
                        props.data.values.feet.arch.left < props.data.values.feet.arch.right ? 'alert-def' : ''}
                    >ляв: {props.data.values.feet.arch.left} см</span>}
                    {(props.data.values.feet.arch.left && props.data.values.feet.arch.right) && <span>, </span>}
                    {props.data.values.feet.arch.right &&
                        <span className={
                        props.data.values.feet.arch.right < props.data.values.feet.arch.left ? 'alert-def' : ''}
                    > десен: {props.data.values.feet.arch.right} см</span>}
                </p>
            </div>
            <h3 className='recommendations-h'><label>Препоръки:</label></h3>
            <p className='centered-print recommendations'>{props.data.values.feet.recommendations}</p>
            <div className="important-info-wrapper">Внимание: Проблемите с ходилата могат да доведат с времето до изкривявания на гръбнака и увреждане на коленете и тазобедрените стави.</div>
            <div className="page-break" />
            <h2>Диагностика на гръбначен стълб</h2>
            <h3>Ъглометрия шиен дял</h3>
            <div className='centered-print'><img src={neck} className='form-img' /></div>
            <div className='left spine-section'>
                <p><label>Флексия (60°-70°):</label> 
                    {props.data.values.spine.neck.flexion && <>{props.data.values.spine.neck.flexion}° {props.data.values.spine.neck.flexion < 60 ? <span className='alert-def'>намален обем на движение</span> : <span className='alert-ok'> в норма</span>}</>}</p>
                <p><label>Екстензия (40°-50°):</label> 
                    {props.data.values.spine.neck.extension && <>{props.data.values.spine.neck.extension}° 
                        {props.data.values.spine.neck.extension < 40 ? <span className='alert-def'>намален обем на движение</span> : 
                        (props.data.values.spine.neck.extension >= 40 && props.data.values.spine.neck.extension <= 50 ? <span className='alert-ok'> в норма</span> : 
                        <span className='alert-ab'>хиперекстензия</span>
                        // (props.data.values.spine.neck.extension > 70 ? <span className='alert-ab'>хиперекстензия</span> : '')
                        )}
                    </>}
                </p>
                <p><label>Латерални наклони (20°-45°):</label> 
                    <span className={goniometryLeftRightClassName(20, 45, props.data.values.spine.neck.lateral.left, props.data.values.spine.neck.lateral.right)}
                    >ляво - {props.data.values.spine.neck.lateral.left}°</span>, <span className={goniometryLeftRightClassName(20, 45, props.data.values.spine.neck.lateral.right, props.data.values.spine.neck.lateral.left)}
                    >дясно - {props.data.values.spine.neck.lateral.right}°</span>
                    {goniometryLeftRight('латерални наклони шиен дял', 'наляво', 'надясно', 20, 45, props.data.values.spine.neck.lateral.left, props.data.values.spine.neck.lateral.right)}
                </p>
                <p><label>Ротация (70°-90°):</label> 
                     <span className={goniometryLeftRightClassName(70, 90, props.data.values.spine.neck.rotation.left, props.data.values.spine.neck.rotation.right)}
                    >ляво - {props.data.values.spine.neck.rotation.left}°</span>, <span className={goniometryLeftRightClassName(70, 90, props.data.values.spine.neck.rotation.right, props.data.values.spine.neck.rotation.left)}
                        >дясно - {props.data.values.spine.neck.rotation.right}°</span>
                    {goniometryLeftRight('ротация шиен дял', 'наляво', 'надясно', 70, 90, props.data.values.spine.neck.rotation.left, props.data.values.spine.neck.rotation.right)}
                </p>
            </div>
            <h3>Общ преглед на гръбначния стълб</h3>
            <div className='card-imgs'>
                    {props.data.values.spine.curvature.map((item, index) => 
                        <div className='spine-img' key={index}>
                            <img src={spineImages[parseInt(item)]} alt={SpineModel.getSpineString(item)} className={item === '1' ? 'outlined-img-ok' : 'outlined-img-alert'} />
                            {/* <span className={item === '1' ? 'alert-ok' : 'alert-not-ok'}>{SpineModel.getSpineString(item)}</span> */}
                        </div>
                    )}
                    {/* <img src={spineN} className={props.data.values.spine.curvature.includes("1") ? 'outlined-img-ok' : ''} />
                    <img src={spineSLL} className={props.data.values.spine.curvature.includes("2") ? 'outlined-img-alert' : ''} /> */}
                    {props.data.values.xRay.length > 0 &&
                    props.data.values.xRay.map((image, index) =>
                        image.base64Image && typeof image.base64Image !== 'object' && (
                            <img key={index} src={image.base64Image} className='xRay-img' alt="xRay" />
                        )
                    )}
            </div>
            <div className='left spine-section'>
                <p><label>Шиен дял:</label> {CervicalModel.getCervicalString(props.data.values.spine.cervical)}</p>
                {/*<p><label>Сила на захват:</label>*/}
                {/*    {props.data.values.spine.grip.left && <span>ляво - {props.data.values.spine.grip.left} кг</span>}*/}
                {/*    {(props.data.values.spine.grip.left && props.data.values.spine.grip.right) && <span>, </span>}*/}
                {/*    {props.data.values.spine.grip.right && <span>дясно - {props.data.values.spine.grip.right} кг</span>}*/}
                {/*    {(props.data.values.spine.grip.left || props.data.values.spine.grip.right) && <span>, </span>}*/}
                {/*    {props.data.values.spine.handedness && <span>{props.data.values.spine.handedness}</span>}*/}
                {/*        </p>*/}
                <p><label>Гръден дял:</label> {props.data.values.spine.thoracic.curv.map((curv, index) => 
                    <span key={index}>
                        { (index ? ', ' : '') + CurvModel.getCurvString(curv)} 
                        {(curv === '1') && (<>{' - ' + String(props.data.values.spine.thoracic.direction) + ' - ' + String(props.data.values.spine.thoracic.degrees) + '°'}</>) }
                    </span>
                )}</p>
                <p><label>Ключица:</label>
                    {props.data.values.spine.collarBone.degrees && <span>{props.data.values.spine.collarBone.degrees}° </span>}
                    {props.data.values.spine.collarBone.direction && <span>{props.data.values.spine.collarBone.direction}</span>}
                </p>
                <p><label>Рамене:</label>
                    {props.data.values.spine.shoulders.degrees && <span>{props.data.values.spine.shoulders.degrees}° </span>}
                    {props.data.values.spine.shoulders.direction && <span>{props.data.values.spine.shoulders.direction}</span>}
                </p>
                <p><label>Лумбален дял:</label> {LumbarModel.getLumbarString(props.data.values.spine.lumbar.lord)}{props.data.values.spine.lumbar.curv.map((curv, index) => 
                    <span key={index}>
                        { ', ' + CurvModel.getCurvString(curv)} 
                        {(curv === '1') && (<>{' - ' + String(props.data.values.spine.lumbar.direction) + ' - ' + String(props.data.values.spine.lumbar.degrees) + '°'}</>) }
                    </span>
                )}</p>
                <p><label>Латерални наклони (30°):</label>
                    {props.data.values.spine.lateral.left !== null && <span className={goniometryLeftRightSingleRefClassName(30, props.data.values.spine.lateral.left, props.data.values.spine.lateral.right)}
                    >ляво - {props.data.values.spine.lateral.left}°</span>}
                    {(props.data.values.spine.lateral.left !== null && props.data.values.spine.lateral.right !== null) && <>, </>}
                        {props.data.values.spine.lateral.right !== null && <span className={goniometryLeftRightSingleRefClassName(30, props.data.values.spine.lateral.right, props.data.values.spine.lateral.left)}
                    >дясно - {props.data.values.spine.lateral.right}°</span>}
                    {(props.data.values.spine.lateral.left !== null && props.data.values.spine.lateral.right !== null) && goniometryLeftRightSingleRef('латерални наклони гръбначен стълб', 'наляво', 'надясно', 30, props.data.values.spine.lateral.left, props.data.values.spine.lateral.right)}
                </p>
                <p><label>Сакрален дял:</label> 
                    {props.data.values.spine.lumbar.curv.length === 0 ? '' : !props.data.values.spine.lumbar.curv.includes('1') ? <span>няма изменение</span> : (props.data.values.spine.lumbar.direction === 'ляво' ? <span>тазът се усуква наляво</span> : <span>тазът се усуква надясно</span>)}
                </p>
            </div>
            <div className='ot-measurements'>
                <h4>Измерване на От(C7-S1) 10см (Измерване подвижността на гръбначния стълб):</h4>{props.data.values.spine.c7s1 && <p className='centered-print'>След направено измерване на От е установен {props.data.values.spine.c7s1 > 10 ? 'увеличен' : (props.data.values.spine.c7s1 === 10 ? 'нормален' : (props.data.values.spine.c7s1 < 10 && props.data.values.spine.c7s1 > 9 ? 'намален' : 'ограничен'))} обем на движение на гръбначен стълб - {props.data.values.spine.c7s1} см</p>}
                <h4>Измерване на От(Th12-S1) 7.5см (Измерване подвижността на гръбначния стълб):</h4>{props.data.values.spine.th12 && <p className='centered-print'>След направено измерване на От е установен {props.data.values.spine.th12 > 7.5 ? 'увеличен' : (props.data.values.spine.th12 === 7.5 ? 'нормален' : (props.data.values.spine.th12 < 7.5 && props.data.values.spine.th12 > 6 ? 'намален' : 'ограничен'))} обем на движение на гръбначен стълб - {props.data.values.spine.th12} см</p>}
                <h4>Измерване на От(C7-Th12) 2.7см (Измерване подвижността на гръбначния стълб):</h4>{props.data.values.spine.c7th12 && <p className='centered-print'>След направено измерване на От е установен {props.data.values.spine.c7th12 > 2.7 ? 'увеличен' : (props.data.values.spine.c7th12 === 2.7 ? 'нормален' : (props.data.values.spine.c7th12 < 2.7 && props.data.values.spine.c7th12 > 2.2 ? 'намален' : 'ограничен'))} обем на движение на гръбначен стълб - {props.data.values.spine.c7th12} см</p>}
            </div>
            {/* <h3><label>Препоръки:</label></h3>{props.data.values.spine.recommendations} */}
            <div className="important-info-wrapper">Измерванията за сколиоза са извършени със сколиометър. <br />{props.data.values.spine.scoliometerNote}</div>
            <div className="page-break" />
            <h2>Ъглометрия - подвижност на стави</h2>
            <div className='goniometry-section'>
            <h3>Тазобедрена става</h3>
            <div className='multi-inp wider top'>
                <div className='wide-col'>
                    <h4>Екстензия-Флексия (15°-0°-125°):</h4>
                    <div className='multi-inp'>
                        <p>
                            <label>Лява:</label>
                            {props.data.values.goniometry.hip.left.extension}° -0°- {props.data.values.goniometry.hip.left.flexion}°<br />
                            {goniometryEvaluation('тазобедрена става', 'екстензия', 'флексия', 15, 125, props.data.values.goniometry.hip.left.extension, props.data.values.goniometry.hip.left.flexion)}<br />
                            {/* {goniometryEvaluation('тазобедрена става', 'екстензия', 'флексия', 15, 125, props.data.values.goniometry.hip.left.extension, props.data.values.goniometry.hip.left.flexion) != 'в норма' && ','} */}
                            {/* {props.data.values.goniometry.hip.left.extension > 15 && <span className='error-message'>{` ${props.data.values.goniometry.hip.left.extension - 15}° хиперекстензия`}</span>}<br /> */}
                            флексия с изпънато коляно: <span className={
                                props.data.values.goniometry.hip.left.flexionStrKnee < 90 ? 'alert-def' : (props.data.values.goniometry.hip.left.flexionStrKnee > 90 && props.data.values.goniometry.hip.left.flexionStrKnee < props.data.values.goniometry.hip.right.flexionStrKnee && 'alert-smallerValue')}
                            >{props.data.values.goniometry.hip.left.flexionStrKnee}°</span> (90°) <br />{props.data.values.goniometry.hip.left.flexionStrKnee && (props.data.values.goniometry.hip.left.flexionStrKnee < 90 ? <span className='alert-def'>намален обем на движение</span> : (props.data.values.goniometry.hip.left.flexionStrKnee > 90 ? <span className='alert-not-ok'>{props.data.values.goniometry.hip.left.flexionStrKnee - 90}° хиперфлексия</span> : <span className='alert-ok'> в норма</span>))}<br /><br />
                            <label>Дясна:</label>{props.data.values.goniometry.hip.right.extension}° -0°- {props.data.values.goniometry.hip.right.flexion}°<br />
                            {goniometryEvaluation('тазобедрена става', 'екстензия', 'флексия', 15, 125, props.data.values.goniometry.hip.right.extension, props.data.values.goniometry.hip.right.flexion)}<br />
                            {/* {props.data.values.goniometry.hip.right.extension > 15 && <span className='error-message'>{`, ${props.data.values.goniometry.hip.right.extension - 15}° хиперекстензия`}</span>}<br /> */}
                            флексия с изпънато коляно: <span className={
                                props.data.values.goniometry.hip.right.flexionStrKnee < 90 ? 'alert-def' : (props.data.values.goniometry.hip.right.flexionStrKnee > 90 && props.data.values.goniometry.hip.right.flexionStrKnee < props.data.values.goniometry.hip.left.flexionStrKnee && 'alert-smallerValue')}
                            >{props.data.values.goniometry.hip.right.flexionStrKnee}°</span> (90°) <br />{props.data.values.goniometry.hip.right.flexionStrKnee && (props.data.values.goniometry.hip.right.flexionStrKnee < 90 ? <span className='alert-def'>намален обем на движение</span> : (props.data.values.goniometry.hip.right.flexionStrKnee > 90 ? <span className='alert-not-ok'>{props.data.values.goniometry.hip.right.flexionStrKnee - 90}° хиперфлексия</span> : <span className='alert-ok'> в норма</span>))}
                        </p>
                    </div>
                    <h4>Абдукция-Аддукция (45°-0°-30°):</h4>
                    <div className='multi-inp'>
                        <p>
                            <label>Лява:</label>{props.data.values.goniometry.hip.left.abduction}° -0°- {props.data.values.goniometry.hip.left.adduction}° <br />
                            {goniometryEvaluation('тазобедрена става', 'абдукция', 'аддукция', 45, 30, props.data.values.goniometry.hip.left.abduction, props.data.values.goniometry.hip.left.adduction)}<br /><br />
                            <label>Дясна:</label>{props.data.values.goniometry.hip.right.abduction}° -0°- {props.data.values.goniometry.hip.right.adduction}°<br />
                            {goniometryEvaluation('тазобедрена става', 'абдукция', 'аддукция', 45, 30, props.data.values.goniometry.hip.right.abduction, props.data.values.goniometry.hip.right.adduction)}
                        </p>
                    </div>
                    <h4>Вътрешна-Външна Ротация (45°-0°-45°):</h4>
                    <div className='multi-inp'>
                        <p>
                            <label>Лява:</label>{props.data.values.goniometry.hip.left.rotationInner}° -0°- {props.data.values.goniometry.hip.left.rotationOuter}°<br />
                            {goniometryEvaluation('тазобедрена става', 'вътрешна ротация', 'външна ротация', 45, 45, props.data.values.goniometry.hip.left.rotationInner, props.data.values.goniometry.hip.left.rotationOuter)}<br /><br />
                            <label>Дясна:</label>{props.data.values.goniometry.hip.right.rotationInner}° -0°- {props.data.values.goniometry.hip.right.rotationOuter}°<br />
                            {goniometryEvaluation('тазобедрена става', 'вътрешна ротация', 'външна ротация', 45, 45, props.data.values.goniometry.hip.right.rotationInner, props.data.values.goniometry.hip.right.rotationOuter)}
                        </p>
                    </div>
                </div>
                <div className='right-col-imgs'>
                    <div><img src={jointsHip} className="goniometry-img"/></div>
                    <div><img src={jointsHip1} className="goniometry-img" /></div>
                    <div><img src={jointsHip2} className="goniometry-img" /></div>
                </div>
            </div>
            <h3>Раменна става</h3>
            <div className='multi-inp wider top'>
                <div className='wide-col'>
                    <h4>Екстензия-Флексия (60°-0°-180°):</h4>
                    <div className='multi-inp'>
                        <p>
                            <label>Лява:</label>{props.data.values.goniometry.shoulder.left.extension}° -0°- {props.data.values.goniometry.shoulder.left.flexion}°<br />
                            {goniometryEvaluation('раменна става', 'екстензия', 'флексия', 60, 180, props.data.values.goniometry.shoulder.left.extension, props.data.values.goniometry.shoulder.left.flexion)}<br />
                            {/* {props.data.values.goniometry.shoulder.left.extension > 60 && <span className='error-message'>{`, ${props.data.values.goniometry.shoulder.left.extension - 60}° хиперекстензия`}</span>}<br /><br /> */}
                            <label>Дясна:</label>{props.data.values.goniometry.shoulder.right.extension}° -0°- {props.data.values.goniometry.shoulder.right.flexion}°<br />
                            {goniometryEvaluation('раменна става', 'екстензия', 'флексия', 60, 180, props.data.values.goniometry.shoulder.right.extension, props.data.values.goniometry.shoulder.right.flexion)}<br />
                            {/* {props.data.values.goniometry.shoulder.right.extension > 60 && <span className='error-message'>{`, ${props.data.values.goniometry.shoulder.right.extension - 60}° хиперекстензия`}</span>} */}
                        </p>
                    </div>
                    <h4>Абдукция-Аддукция (180°-0°-0°):</h4>
                    <div className='multi-inp'>
                        <p>
                            <label>Лява:</label>{props.data.values.goniometry.shoulder.left.abduction}° -0°- {props.data.values.goniometry.shoulder.left.adduction}°<br />
                            {goniometryEvaluation('раменна става', 'абдукция', 'аддукция', 180, 0, props.data.values.goniometry.shoulder.left.abduction, props.data.values.goniometry.shoulder.left.adduction)}<br /><br />
                            <label>Дясна:</label>{props.data.values.goniometry.shoulder.right.abduction}° -0°- {props.data.values.goniometry.shoulder.right.adduction}°<br />
                            {goniometryEvaluation('раменна става', 'абдукция', 'аддукция', 180, 0, props.data.values.goniometry.shoulder.right.abduction, props.data.values.goniometry.shoulder.right.adduction)}
                        </p>
                    </div>
                    <h4>Вътрешна-Външна Ротация (90°-0°-90°):</h4>
                    <div className='multi-inp'>
                        <p>
                            <label>Лява:</label>{props.data.values.goniometry.shoulder.left.rotationInner}° -0°- {props.data.values.goniometry.shoulder.left.rotationOuter}°<br />
                            {goniometryEvaluation('раменна става', 'вътрешна ротация', 'външна ротация', 90, 90, props.data.values.goniometry.shoulder.left.rotationInner, props.data.values.goniometry.shoulder.left.rotationOuter)}<br /><br />
                            <label>Дясна:</label>{props.data.values.goniometry.shoulder.right.rotationInner}° -0°- {props.data.values.goniometry.shoulder.right.rotationOuter}°<br />
                            {goniometryEvaluation('раменна става', 'вътрешна ротация', 'външна ротация', 90, 90, props.data.values.goniometry.shoulder.right.rotationInner, props.data.values.goniometry.shoulder.right.rotationOuter)}
                        </p>
                    </div>
                </div>
                <div className='right-col-imgs'>
                    <div><img src={jointsShoulder} className="goniometry-img"/></div>
                    <div><img src={jointsShoulder1} className="goniometry-img" /></div>
                    <div><img src={jointsShoulder2} className="goniometry-img" /></div>
                </div>
            </div>
            <h3>Глезенна става</h3>
            <div className='control multi-inp wider top'>
                <div className='wide-col'>
                    <h4>Дорзална флексия-Плантарна флексия (26°-0°-48°):</h4>
                    <div className='multi-inp'>
                        <p>
                            <label>Лява:</label>{props.data.values.goniometry.ankle.left.flexion}° -0°- {props.data.values.goniometry.ankle.left.extension}°<br />
                            {goniometryEvaluation('глезенна става', 'плантарна флексия', 'дорзална флексия', 48, 26, props.data.values.goniometry.ankle.left.extension, props.data.values.goniometry.ankle.left.flexion)}<br />
                            {/* {props.data.values.goniometry.ankle.left.extension > 48 && <span className='error-message'>{`, ${props.data.values.goniometry.ankle.left.extension - 48}° хиперекстензия`}</span>}<br /><br /> */}
                            <label>Дясна:</label>{props.data.values.goniometry.ankle.right.flexion}° -0°- {props.data.values.goniometry.ankle.right.extension}°<br />
                            {goniometryEvaluation('глезенна става', 'плантарна флексия', 'дорзална флексия', 48, 26, props.data.values.goniometry.ankle.right.extension, props.data.values.goniometry.ankle.right.flexion)}<br />
                            {/* {props.data.values.goniometry.ankle.right.extension > 48 && <span className='error-message'>{`, ${props.data.values.goniometry.ankle.right.extension - 48}° хиперекстензия`}</span>} */}
                        </p>
                    </div>
                </div>
                <div className='right-col-imgs'>
                    <div><img src={jointsAnkle} className="goniometry-img"/></div>
                </div>
            </div>
            <h3>Колянна става</h3>
            <div className='multi-inp wider top'>
                <div className='wide-col'>
                    <h4>Екстензия-Флексия (0°-0°-130°):</h4>
                    <div className='multi-inp'>
                        <p>
                            <label>Лява:</label>{props.data.values.goniometry.knee.left.extension}° -0°- {props.data.values.goniometry.knee.left.flexion}°<br />
                            {goniometryEvaluation('колянна става', 'екстензия', 'флексия', 0, 130, props.data.values.goniometry.knee.left.extension, props.data.values.goniometry.knee.left.flexion)}<br />
                            {/* {props.data.values.goniometry.knee.left.extension > 0 && <span className='error-message'>{`, ${props.data.values.goniometry.knee.left.extension}° хиперекстензия`}</span>}<br /><br /> */}
                            <label>Дясна:</label>{props.data.values.goniometry.knee.right.extension}° -0°- {props.data.values.goniometry.knee.right.flexion}°<br />
                            {goniometryEvaluation('колянна става', 'екстензия', 'флексия', 0, 130, props.data.values.goniometry.knee.right.extension, props.data.values.goniometry.knee.right.flexion)}<br />
                            {/* {props.data.values.goniometry.knee.right.extension > 0 && <span className='error-message'>{`, ${props.data.values.goniometry.knee.right.extension}° хиперекстензия`}</span>} */}
                        </p>
                    </div>
                </div>
                <div className='right-col-imgs'>
                    <div><img src={jointsKnee} className="goniometry-img"/></div>
                </div>
            </div>
            <h3>Лакътна става</h3>
            <div className='multi-inp wider top'>
                <div className='wide-col'>
                    <h4>Екстензия-Флексия (0°-0°-145°):</h4>
                    <div className='multi-inp'>
                        <p>
                            <label>Лява:</label>{props.data.values.goniometry.elbow.left.extension}° -0°- {props.data.values.goniometry.elbow.left.flexion}°<br />
                            {goniometryEvaluation('лакътна става', 'екстензия', 'флексия', 0, 145, props.data.values.goniometry.elbow.left.extension, props.data.values.goniometry.elbow.left.flexion)}<br />
                            {/* {props.data.values.goniometry.elbow.left.extension >= 15 && <span className='error-message'>{`, ${props.data.values.goniometry.elbow.left.extension}° хиперекстензия`}</span>}<br /><br /> */}
                            <label>Дясна:</label>{props.data.values.goniometry.elbow.right.extension}° -0°- {props.data.values.goniometry.elbow.right.flexion}°<br />
                            {goniometryEvaluation('лакътна става', 'екстензия', 'флексия', 0, 145, props.data.values.goniometry.elbow.right.extension, props.data.values.goniometry.elbow.right.flexion)}<br />
                            {/* {props.data.values.goniometry.elbow.right.extension >= 15 && <span className='error-message'>{`, ${props.data.values.goniometry.elbow.right.extension}° хиперекстензия`}</span>} */}
                        </p>
                    </div>
                    <h4>Супинация-Пронация (90°-0°-90°):</h4>
                    <div className='multi-inp'>
                        <p>
                            <label>Лява:</label>{props.data.values.goniometry.elbow.left.supination}° -0°- {props.data.values.goniometry.elbow.left.pronation}°<br />
                            {goniometryEvaluation('лакътна става', 'супинация', 'пронация', 90, 90, props.data.values.goniometry.elbow.left.supination, props.data.values.goniometry.elbow.left.pronation)}<br /><br />
                            <label>Дясна:</label>{props.data.values.goniometry.elbow.right.supination}° -0°- {props.data.values.goniometry.elbow.right.pronation}°<br />
                            {goniometryEvaluation('лакътна става', 'супинация', 'пронация', 90, 90, props.data.values.goniometry.elbow.right.supination, props.data.values.goniometry.elbow.right.pronation)}
                        </p>
                    </div>
                </div>
                <div className='right-col-imgs'>
                    <div><img src={jointsElbow} className="goniometry-img"/></div>
                    <div><img src={jointsElbow1} className="goniometry-img"/></div>
                </div>
            </div>
            <h3>Става на китката</h3>
            <div className='multi-inp wider top'>
                <div className='wide-col'>
                    <h4>Екстензия-Флексия (70°-0°-90°):</h4>
                    <div className='multi-inp'>
                        <p>
                            <label>Лява:</label>{props.data.values.goniometry.wrist.left.extension}° -0°- {props.data.values.goniometry.wrist.left.flexion}°<br />
                            {goniometryEvaluation('става на китката', 'екстензия', 'флексия', 70, 90, props.data.values.goniometry.wrist.left.extension, props.data.values.goniometry.wrist.left.flexion)}<br /><br />
                            <label>Дясна:</label>{props.data.values.goniometry.wrist.right.extension}° -0°- {props.data.values.goniometry.wrist.right.flexion}°<br />
                            {goniometryEvaluation('става на китката', 'екстензия', 'флексия', 70, 90, props.data.values.goniometry.wrist.right.extension, props.data.values.goniometry.wrist.right.flexion)}
                        </p>
                    </div>
                    <h4>Радиално-Улнарно отвеждане (20°-0°-40°):</h4>
                    <div className='multi-inp'>
                        <p>
                            <label>Лява:</label>{props.data.values.goniometry.wrist.left.radial}° -0°- {props.data.values.goniometry.wrist.left.ulnar}°<br />
                            {goniometryEvaluation('става на китката', 'радиално отвеждане', 'улнарно отвеждане', 20, 40, props.data.values.goniometry.wrist.left.radial, props.data.values.goniometry.wrist.left.ulnar)}<br /><br />
                            <label>Дясна:</label>{props.data.values.goniometry.wrist.right.radial}° -0°- {props.data.values.goniometry.wrist.right.ulnar}°<br />
                            {goniometryEvaluation('става на китката', 'радиално отвеждане', 'улнарно отвеждане', 20, 40, props.data.values.goniometry.wrist.right.radial, props.data.values.goniometry.wrist.right.ulnar)}
                        </p>
                    </div>
                </div>
                <div className='right-col-imgs'>
                    <div><img src={jointsWrist} className="goniometry-img-s"/></div>
                    <div><img src={jointsWrist1} className="goniometry-img-s"/></div>
                </div>
            </div>
            </div>
            <div className="page-break" />
            <h2>Силометрия</h2>
            <div className="wide-col">
                <div className="centered-print"><label>Екстензори на гърба:</label></div>
                <div className="row-value">
                    <span>&nbsp;</span>
                    {/*<span>{props.data.values.forcemetry.backExt && <>{props.data.values.forcemetry.backExt} кг</>}</span>*/}
                    <span>{props.data.values.forcemetry?.backExt && <>{props.data.values.forcemetry.backExt} кг</>}</span>
                    <span>&nbsp;</span>
                </div>
                <div className="centered-print"><label>Трапец:</label></div>
                <div className="row-value">
                    <div className="row-icon-d left">Ляво</div>
                    <div className="data">
                        <span>{props.data.values.forcemetry?.trapezius.left && <>{props.data.values.forcemetry.trapezius.left} кг</>}</span>
                        <span>{props.data.values.forcemetry?.trapezius.right && <>{props.data.values.forcemetry.trapezius.right} кг</>}</span>
                    </div>
                    <div className="row-icon-d right">Дясно</div>
                </div>
                <div className="centered-print"><label>Екстензори на врата:</label></div>
                <div className="row-value">
                    <span>&nbsp;</span>
                    <span>{props.data.values.forcemetry?.neckExt && <>{props.data.values.forcemetry.neckExt} кг</>}</span>
                    <span>&nbsp;</span>
                </div>
                <div className="centered-print"><label>Флексори на врата:</label></div>
                <div className="row-value">
                    <span>&nbsp;</span>
                    <span>{props.data.values.forcemetry?.neckFlex && <>{props.data.values.forcemetry.neckFlex} кг</>}</span>
                    <span>&nbsp;</span>
                </div>
                <div className="centered-print"><label>Латерални наклони на врата:</label></div>
                <div className="row-value">
                    <div className="row-icon-d left">Ляво</div>
                    <div className="data">
                        <span>{props.data.values.forcemetry?.neckLateral.left && <>{props.data.values.forcemetry.neckLateral.left} кг</>}</span>
                        <span>{props.data.values.forcemetry?.neckLateral.right && <>{props.data.values.forcemetry.neckLateral.right} кг</>}</span>
                    </div>
                    <div className="row-icon-d right">Дясно</div>
                </div>
                <div className="centered-print"><label>Teres major:</label></div>
                <div className="row-value">
                    <div className="row-icon-d left">Ляво</div>
                    <div className="data">
                        <span>{props.data.values.forcemetry?.teresMajor.left && <>{props.data.values.forcemetry.teresMajor.left} кг</>}</span>
                        <span>{props.data.values.forcemetry?.teresMajor.right && <>{props.data.values.forcemetry.teresMajor.right} кг</>}</span>
                    </div>
                    <div className="row-icon-d right">Дясно</div>
                </div>
                <div className="centered-print"><label>Teres minor:</label></div>
                <div className="row-value">
                    <div className="row-icon-d left">Ляво</div>
                    <div className="data">
                        <span>{props.data.values.forcemetry?.teresMinor.left && <>{props.data.values.forcemetry.teresMinor.left} кг</>}</span>
                        <span>{props.data.values.forcemetry?.teresMinor.right && <>{props.data.values.forcemetry.teresMinor.right} кг</>}</span>
                    </div>
                    <div className="row-icon-d right">Дясно</div>
                </div>
                <div className="centered-print"><label>Сила на захват:</label></div>
                <div className="row-value">
                    <div className="row-icon-d left">Ляво</div>
                    <div className="data">
                        <span>{props.data.values.spine.grip.left && <>{props.data.values.spine.grip.left} кг</>}</span>
                        <span>{props.data.values.spine.grip.right && <>{props.data.values.spine.grip.right} кг</>}</span>
                    </div>
                    <div className="row-icon-d right">Дясно</div>
                </div>
                <div className="centered-print"><label>Бицепс:</label></div>
                <div className="row-value">
                    <div className="row-icon-d left">Ляво</div>
                    <div className="data">
                        <span>{props.data.values.forcemetry?.biceps.left && <>{props.data.values.forcemetry.biceps.left} кг</>}</span>
                        <span>{props.data.values.forcemetry?.biceps.right && <>{props.data.values.forcemetry.biceps.right} кг</>}</span>
                    </div>
                    <div className="row-icon-d right">Дясно</div>
                </div>
                <div className="centered-print"><label>Трицепс:</label></div>
                <div className="row-value">
                    <div className="row-icon-d left">Ляво</div>
                    <div className="data">
                        <span>{props.data.values.forcemetry?.triceps.left && <>{props.data.values.forcemetry.triceps.left} кг</>}</span>
                        <span>{props.data.values.forcemetry?.triceps.right && <>{props.data.values.forcemetry.triceps.right} кг</>}</span>
                    </div>
                    <div className="row-icon-d right">Дясно</div>
                </div>
                <div className="centered-print"><label>Екстензори на китката:</label></div>
                <div className="row-value">
                    <div className="row-icon-d left">Ляво</div>
                    <div className="data">
                        <span>{props.data.values.forcemetry?.wristExt.left && <>{props.data.values.forcemetry.wristExt.left} кг</>}</span>
                        <span>{props.data.values.forcemetry?.wristExt.right && <>{props.data.values.forcemetry.wristExt.right} кг</>}</span>
                    </div>
                    <div className="row-icon-d right">Дясно</div>
                </div>
                <div className="centered-print"><label>Флексори на китката:</label></div>
                <div className="row-value">
                    <div className="row-icon-d left">Ляво</div>
                    <div className="data">
                        <span>{props.data.values.forcemetry?.wristFlex.left && <>{props.data.values.forcemetry.wristFlex.left} кг</>}</span>
                        <span>{props.data.values.forcemetry?.wristFlex.right && <>{props.data.values.forcemetry.wristFlex.right} кг</>}</span>
                    </div>
                    <div className="row-icon-d right">Дясно</div>
                </div>
                <div className="centered-print"><label>Латисмус Дорзи:</label></div>
                <div className="row-value">
                    <div className="row-icon-d left">Ляво</div>
                    <div className="data">
                        <span>{props.data.values.forcemetry?.latDorsi.left && <>{props.data.values.forcemetry.latDorsi.left} кг</>}</span>
                        <span>{props.data.values.forcemetry?.latDorsi.right && <>{props.data.values.forcemetry.latDorsi.right} кг</>}</span>
                    </div>
                    <div className="row-icon-d right">Дясно</div>
                </div>
                <div className="centered-print"><label>Предно рамо:</label></div>
                <div className="row-value">
                    <div className="row-icon-d left">Ляво</div>
                    <div className="data">
                        <span>{props.data.values.forcemetry?.shoulderFront.left && <>{props.data.values.forcemetry.shoulderFront.left} кг</>}</span>
                        <span>{props.data.values.forcemetry?.shoulderFront.right && <>{props.data.values.forcemetry.shoulderFront.right} кг</>}</span>
                    </div>
                    <div className="row-icon-d right">Дясно</div>
                </div>
                <div className="centered-print"><label>Средно рамо:</label></div>
                <div className="row-value">
                    <div className="row-icon-d left">Ляво</div>
                    <div className="data">
                        <span>{props.data.values.forcemetry?.shoulderMid.left && <>{props.data.values.forcemetry.shoulderMid.left} кг</>}</span>
                        <span>{props.data.values.forcemetry?.shoulderMid.right && <>{props.data.values.forcemetry.shoulderMid.right} кг</>}</span>
                    </div>
                    <div className="row-icon-d right">Дясно</div>
                </div>
                <div className="centered-print"><label>Задно рамо:</label></div>
                <div className="row-value">
                    <div className="row-icon-d left">Ляво</div>
                    <div className="data">
                        <span>{props.data.values.forcemetry?.shoulderBack.left && <>{props.data.values.forcemetry.shoulderBack.left} кг</>}</span>
                        <span>{props.data.values.forcemetry?.shoulderBack.right && <>{props.data.values.forcemetry.shoulderBack.right} кг</>}</span>
                    </div>
                    <div className="row-icon-d right">Дясно</div>
                </div>
                <div className="centered-print"><label>Гръдни мускули:</label></div>
                <div className="row-value">
                    <div className="row-icon-d left">Ляво</div>
                    <div className="data">
                        <span>{props.data.values.forcemetry?.chest.left && <>{props.data.values.forcemetry.chest.left} кг</>}</span>
                        <span>{props.data.values.forcemetry?.chest.right && <>{props.data.values.forcemetry.chest.right} кг</>}</span>
                    </div>
                    <div className="row-icon-d right">Дясно</div>
                </div>
                <div className="centered-print"><label>Коремни мускули:</label></div>
                <div className="row-value">
                    <span>&nbsp;</span>
                    <span>{props.data.values.forcemetry?.abdomen && <>{props.data.values.forcemetry.abdomen} кг</>}</span>
                    <span>&nbsp;</span>
                </div>
                <div className="centered-print"><label>Латерален наклон на кръста:</label></div>
                <div className="row-value">
                    <div className="row-icon-d left">Ляво</div>
                    <div className="data">
                        <span>{props.data.values.forcemetry?.waistLat.left && <>{props.data.values.forcemetry.waistLat.left} кг</>}</span>
                        <span>{props.data.values.forcemetry?.waistLat.right && <>{props.data.values.forcemetry.waistLat.right} кг</>}</span>
                    </div>
                    <div className="row-icon-d right">Дясно</div>
                </div>
                <div className="centered-print"><label>Глутеус:</label></div>
                <div className="row-value">
                    <div className="row-icon-d left">Ляво</div>
                    <div className="data">
                        <span>{props.data.values.forcemetry?.gluteus.left && <>{props.data.values.forcemetry.gluteus.left} кг</>}</span>
                        <span>{props.data.values.forcemetry?.gluteus.right && <>{props.data.values.forcemetry.gluteus.right} кг</>}</span>
                    </div>
                    <div className="row-icon-d right">Дясно</div>
                </div>
                <div className="centered-print"><label>Задно бедро:</label></div>
                <div className="row-value">
                    <div className="row-icon-d left">Ляво</div>
                    <div className="data">
                        <span>{props.data.values.forcemetry?.backHip.left && <>{props.data.values.forcemetry.backHip.left} кг</>}</span>
                        <span>{props.data.values.forcemetry?.backHip.right && <>{props.data.values.forcemetry.backHip.right} кг</>}</span>
                    </div>
                    <div className="row-icon-d right">Дясно</div>
                </div>
                <div className="centered-print"><label>Подбедрица:</label></div>
                <div className="row-value">
                    <div className="row-icon-d left">Ляво</div>
                    <div className="data">
                        <span>{props.data.values.forcemetry?.shank.left && <>{props.data.values.forcemetry.shank.left} кг</>}</span>
                        <span>{props.data.values.forcemetry?.shank.right && <>{props.data.values.forcemetry.shank.right} кг</>}</span>
                    </div>
                    <div className="row-icon-d right">Дясно</div>
                </div>
                <div className="centered-print"><label>Предно бедро:</label></div>
                <div className="row-value">
                    <div className="row-icon-d left">Ляво</div>
                    <div className="data">
                        <span>{props.data.values.forcemetry?.hip.left && <>{props.data.values.forcemetry.hip.left} кг</>}</span>
                        <span>{props.data.values.forcemetry?.hip.right && <>{props.data.values.forcemetry.hip.right} кг</>}</span>
                    </div>
                    <div className="row-icon-d right">Дясно</div>
                </div>
                <div className="centered-print"><label>Тибиалис:</label></div>
                <div className="row-value">
                    <div className="row-icon-d left">Ляво</div>
                    <div className="data">
                        <span>{props.data.values.forcemetry?.tibialis.left && <>{props.data.values.forcemetry.tibialis.left} кг</>}</span>
                        <span>{props.data.values.forcemetry?.tibialis.right && <>{props.data.values.forcemetry.tibialis.right} кг</>}</span>
                    </div>
                    <div className="row-icon-d right">Дясно</div>
                </div>
                <div className="centered-print"><label>Абдуктори:</label></div>
                <div className="row-value">
                    <div className="row-icon-d left">Ляво</div>
                    <div className="data">
                        <span>{props.data.values.forcemetry?.abductors.left && <>{props.data.values.forcemetry.abductors.left} кг</>}</span>
                        <span>{props.data.values.forcemetry?.abductors.right && <>{props.data.values.forcemetry.abductors.right} кг</>}</span>
                    </div>
                    <div className="row-icon-d right">Дясно</div>
                </div>
                <div className="centered-print"><label>Аддуктори:</label></div>
                <div className="row-value">
                    <div className="row-icon-d left">Ляво</div>
                    <div className="data">
                        <span>{props.data.values.forcemetry?.adductors.left && <>{props.data.values.forcemetry.adductors.left} кг</>}</span>
                        <span>{props.data.values.forcemetry?.adductors.right && <>{props.data.values.forcemetry.adductors.right} кг</>}</span>
                    </div>
                    <div className="row-icon-d right">Дясно</div>
                </div>
            </div>
            <div className="page-break" />
            <h2>Оценка за еластичност на основни сухожилия и мускули</h2>
            <div className='multi-inp wider top'>
                <div className='left half'>
                    {/*Трапец*/}
                    <div className='control-single-line'><label>Трапец:</label>
                        {(props.data.values.forcemetry?.trapezius.left &&
                            props.data.values.forcemetry.trapezius.left !== '' &&
                            props.data.values.forcemetry?.trapezius.right &&
                            props.data.values.forcemetry.trapezius.right !== '') ? (
                            (() => {
                                const leftForce = props.data.values.forcemetry?.trapezius.left;
                                const rightForce = props.data.values.forcemetry?.trapezius.right;
                                const shorteningResult = musclesEvaluationSingleShortening('', 'флексия', 60, 70, props.data.values.spine.neck.flexion);

                                return (
                                    <>
                                        {shorteningResult && shorteningResult}
                                        {(shorteningResult && leftForce !== rightForce) && ', '}
                                        {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                    </>
                                );
                            })()
                        ) : (
                            musclesEvaluationSingle('', 'флексия', 60, 70, props.data.values.spine.neck.flexion)
                        )}
                    </div>
                    {/*Стерноклеидомастоиден мускул*/}
                    <div className='control-single-line'><label>Стерноклеидомастоиден мускул:</label>
                        {(props.data.values.spine.shoulders.degrees && props.data.values.spine.shoulders.direction) ? (
                            <>
                                {props.data.values.spine.shoulders.direction === 'ляво' ?
                                    'скъсяване отляво' : 'скъсяване отдясно'
                                }
                                {musclesEvaluationSingleWeakness('', 'екстензия', 40, 50, props.data.values.spine.neck.extension) &&
                                    `, ${musclesEvaluationSingleWeakness('', 'екстензия', 40, 50, props.data.values.spine.neck.extension)}`}
                            </>
                        ) : (
                            musclesEvaluationSingle('', 'екстензия', 40, 50, props.data.values.spine.neck.extension)
                        )}
                    </div>
                    {/*Splenius Capitis*/}
                    <div className='control-single-line'><label>Splenius Capitis:</label>
                        {(props.data.values.elasticity.levatur === '1' || props.data.values.elasticity.levatur === '' || props.data.values.elasticity.levatur === null) ? (
                                props.data.values.spine.shoulders.degrees && props.data.values.spine.shoulders.direction) ? (
                                <>
                                    {props.data.values.spine.shoulders.direction === 'ляво' ?
                                        'скъсяване отдясно' : 'скъсяване отляво'
                                    }
                                    {musclesEvaluationLatRotWeakness('', 'ротация', 70, 90, props.data.values.spine.neck.rotation.left, props.data.values.spine.neck.rotation.right) &&
                                        `, ${musclesEvaluationLatRotWeakness('', 'ротация', 70, 90, props.data.values.spine.neck.rotation.left, props.data.values.spine.neck.rotation.right)}`}
                                </>
                            ) : (
                                musclesEvaluationLatRot('', 'ротация', 70, 90, props.data.values.spine.neck.rotation.left, props.data.values.spine.neck.rotation.right)
                            ) :
                            LevaturModel.getLevaturString(props.data.values.elasticity.levatur)
                        }
                    </div>
                    {/*Splenius Cervicis*/}
                    <div className='control-single-line'><label>Splenius Cervicis:</label>
                        {(props.data.values.elasticity.levatur === '1' || props.data.values.elasticity.levatur === '' || props.data.values.elasticity.levatur === null) ? (
                                props.data.values.spine.shoulders.degrees && props.data.values.spine.shoulders.direction) ? (
                                <>
                                    {props.data.values.spine.shoulders.direction === 'ляво' ?
                                        'скъсяване отдясно' : 'скъсяване отляво'
                                    }
                                    {musclesEvaluationLatRotWeakness('', 'ротация', 70, 90, props.data.values.spine.neck.rotation.left, props.data.values.spine.neck.rotation.right) &&
                                        `, ${musclesEvaluationLatRotWeakness('', 'ротация', 70, 90, props.data.values.spine.neck.rotation.left, props.data.values.spine.neck.rotation.right)}`}
                                </>
                            ) : (
                                musclesEvaluationLatRot('', 'ротация', 70, 90, props.data.values.spine.neck.rotation.left, props.data.values.spine.neck.rotation.right)
                            ) :
                            LevaturModel.getLevaturString(props.data.values.elasticity.levatur)
                        }
                    </div>
                    {/*Levatur Scapulae*/}
                    <div className='control-single-line'><label>Levatur Scapulae:</label>
                        {(props.data.values.elasticity.levatur === '1' || props.data.values.elasticity.levatur === '' || props.data.values.elasticity.levatur === null) ? (
                                props.data.values.spine.shoulders.degrees && props.data.values.spine.shoulders.direction) ? (
                                <>
                                    {props.data.values.spine.shoulders.direction === 'ляво' ?
                                        'скъсяване отдясно' : 'скъсяване отляво'
                                    }
                                    {musclesEvaluationLatRotWeakness('', 'латерални наклони в шиен дял', 20, 45, props.data.values.spine.neck.lateral.left, props.data.values.spine.neck.lateral.right) &&
                                        `, ${musclesEvaluationLatRotWeakness('', 'латерални наклони в шиен дял', 20, 45, props.data.values.spine.neck.lateral.left, props.data.values.spine.neck.lateral.right)}`}
                                </>
                            ) : (
                                musclesEvaluationLatRot('', 'латерални наклони в шиен дял', 20, 45, props.data.values.spine.neck.lateral.left, props.data.values.spine.neck.lateral.right)
                            ) :
                            LevaturModel.getLevaturString(props.data.values.elasticity.levatur)
                        }
                    </div>
                    {/*Platysma*/}
                    <div className='control-single-line'><label>Platysma:</label> {musclesEvaluationSingle('', 'екстензия', 40, 50, props.data.values.spine.neck.extension)}</div>
                    {/*Гръбни мускули*/}
                    <div className='control-single-line'><label>Гръбни мускули:</label>
                        {(props.data.values.spine.lumbar.curv.length > 0 || props.data.values.spine.thoracic.curv.length > 0 || (props.data.values.spine.curvature.length > 0 && !props.data.values.spine.curvature.includes('1'))) ? 
                        <span>мускулен дисбаланс</span> : <span>в норма</span>
                        }
                    </div>
                    {/*Quadratus Lumborum*/}
                    <div className='control-single-line'><label>Quadratus Lumborum:</label>
                    {(props.data.values.forcemetry?.waistLat.left &&
                        props.data.values.forcemetry.waistLat.left !== '' &&
                        props.data.values.forcemetry?.waistLat.right &&
                        props.data.values.forcemetry.waistLat.right !== '') ? (
                        (() => {
                            const leftForce = props.data.values.forcemetry?.waistLat.left;
                            const rightForce = props.data.values.forcemetry?.waistLat.right;
                            const shorteningResult = musclesEvaluationLatRotShortening('', 'латерални наклони в гръбначен стълб', 30, 30, props.data.values.spine.lateral.left, props.data.values.spine.lateral.right);

                            return (
                                <>
                                    {shorteningResult && shorteningResult}
                                    {(shorteningResult && leftForce !== rightForce) && ', '}
                                    {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                </>
                            );
                        })()
                        ) : (
                            musclesEvaluationLatRot('', 'латерални наклони в гръбначен стълб', 30, 30, props.data.values.spine.lateral.left, props.data.values.spine.lateral.right)
                    )}
                    {/*    (props.data.values.forcemetry?.waistLat.left !== props.data.values.forcemetry?.waistLat.right)) ? (*/}
                    {/*        <>*/}
                    {/*            {musclesEvaluationLatRotShortening('','латерални наклони в гръбначен стълб',30,30, props.data.values.spine.lateral.left, props.data.values.spine.lateral.right) &&*/}
                    {/*                `${musclesEvaluationLatRotShortening('','латерални наклони в гръбначен стълб',30,30, props.data.values.spine.lateral.left, props.data.values.spine.lateral.right)}, `}*/}
                    {/*            {props.data.values.forcemetry?.waistLat.left < props.data.values.forcemetry?.waistLat.right ?*/}
                    {/*                'слабост отляво' : 'слабост отдясно'*/}
                    {/*            }*/}
                    {/*        </>) :*/}
                    {/*    musclesEvaluationLatRot('', 'латерални наклони в гръбначен стълб', 30, 30, props.data.values.spine.lateral.left, props.data.values.spine.lateral.right)*/}
                    {/*}*/}
                    </div>
                    {/*Предно рамо*/}
                    <div className='control-single-line'><label>Предно рамо:</label>
                        {(props.data.values.forcemetry?.shoulderFront.left &&
                            props.data.values.forcemetry.shoulderFront.left !== '' &&
                            props.data.values.forcemetry?.shoulderFront.right &&
                            props.data.values.forcemetry.shoulderFront.right !== '') ? (
                            (() => {
                                const leftForce = props.data.values.forcemetry?.shoulderFront.left;
                                const rightForce = props.data.values.forcemetry?.shoulderFront.right;
                                const shorteningResult = musclesEvaluationShortening('', 'екстензия', 0, 60, props.data.values.goniometry.shoulder.left.extension, props.data.values.goniometry.shoulder.right.extension);

                                return (
                                    <>
                                        {shorteningResult && shorteningResult}
                                        {(shorteningResult && leftForce !== rightForce) && ', '}
                                        {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                    </>
                                );
                            })()
                        ) : (
                            musclesEvaluation('', 'екстензия', 0, 60, props.data.values.goniometry.shoulder.left.extension, props.data.values.goniometry.shoulder.right.extension)
                        )}
                    </div>
                    {/*Задно рамо*/}
                    <div className='control-single-line'><label>Задно рамо:</label>
                        {(props.data.values.forcemetry?.shoulderBack.left &&
                            props.data.values.forcemetry.shoulderBack.left !== '' &&
                            props.data.values.forcemetry?.shoulderBack.right &&
                            props.data.values.forcemetry.shoulderBack.right !== '') ? (
                            (() => {
                                const leftForce = props.data.values.forcemetry?.shoulderBack.left;
                                const rightForce = props.data.values.forcemetry?.shoulderBack.right;
                                const shorteningResult = musclesEvaluationShortening('', 'флексия', 0, 180, props.data.values.goniometry.shoulder.left.flexion, props.data.values.goniometry.shoulder.right.flexion);

                                return (
                                    <>
                                        {shorteningResult && shorteningResult}
                                        {(shorteningResult && leftForce !== rightForce) && ', '}
                                        {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                    </>
                                );
                            })()
                        ) : (
                            musclesEvaluation('', 'флексия', 0, 180, props.data.values.goniometry.shoulder.left.flexion, props.data.values.goniometry.shoulder.right.flexion)
                        )}
                    </div>
                    {/*Latisimus Dorsi*/}
                    <div className='control-single-line'><label>Latisimus Dorsi:</label>
                        {(props.data.values.forcemetry?.latDorsi.left &&
                            props.data.values.forcemetry.latDorsi.left !== '' &&
                            props.data.values.forcemetry?.latDorsi.right &&
                            props.data.values.forcemetry.latDorsi.right !== '') ? (
                            (() => {
                                const leftForce = props.data.values.forcemetry?.latDorsi.left;
                                const rightForce = props.data.values.forcemetry?.latDorsi.right;
                                const shorteningResult = musclesEvaluationShortening('', 'флексия', 0, 180, props.data.values.goniometry.shoulder.left.flexion, props.data.values.goniometry.shoulder.right.flexion);

                                return (
                                    <>
                                        {shorteningResult && shorteningResult}
                                        {(shorteningResult && leftForce !== rightForce) && ', '}
                                        {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                    </>
                                );
                            })()
                        ) : (
                            musclesEvaluation('', 'флексия', 0, 180, props.data.values.goniometry.shoulder.left.flexion, props.data.values.goniometry.shoulder.right.flexion)
                        )}
                    </div>
                    {/*Гръдни мускули*/}
                    <div className='control-single-line'><label>Гръдни мускули:</label>
                        {(props.data.values.forcemetry?.chest.left &&
                            props.data.values.forcemetry.chest.left !== '' &&
                            props.data.values.forcemetry?.chest.right &&
                            props.data.values.forcemetry.chest.right !== '') ? (
                            (() => {
                                const leftForce = props.data.values.forcemetry?.chest.left;
                                const rightForce = props.data.values.forcemetry?.chest.right;
                                const shorteningResult = musclesEvaluationShortening('', 'абдукция', 0, 180, props.data.values.goniometry.shoulder.left.abduction, props.data.values.goniometry.shoulder.right.abduction);

                                return (
                                    <>
                                        {shorteningResult && shorteningResult}
                                        {(shorteningResult && leftForce !== rightForce) && ', '}
                                        {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                    </>
                                );
                            })()
                        ) : (
                            musclesEvaluation('', 'абдукция', 0, 180, props.data.values.goniometry.shoulder.left.abduction, props.data.values.goniometry.shoulder.right.abduction)
                        )}
                    </div>
                    {/*Коремни мускули*/}
                    <div className='control-single-line'><label>Коремни мускули:</label> {ElasticityModel.getElasticityString(props.data.values.elasticity.abdomen)}</div>
                    {/*Ахилесово сухожилие*/}
                    <div className='control-single-line'><label>Ахилесово сухожилие:</label>
                        {
                            (() => {
                                const evaluation = musclesEvaluation(
                                    '',
                                    'флексия',
                                    0,
                                    26,
                                    props.data.values.goniometry.ankle.left.flexion,
                                    props.data.values.goniometry.ankle.right.flexion
                                );

                                switch (evaluation) {
                                    case '':
                                        return '';
                                    case 'в норма':
                                    case 'слабост отдясно':
                                    case 'слабост':
                                    case 'слабост отляво':
                                    case 'лека слабост повече отляво':
                                    case 'слабост повече отляво':
                                    case 'лека слабост':
                                    case 'лека слабост повече отдясно':
                                    case 'слабост повече отдясно':
                                        return 'в норма';
                                    case 'скъсена мускулатура':
                                        return 'скъсена мускулатура';

                                    case 'скъсяване отдясно':
                                        return 'скъсяване отдясно';
                                    case 'скъсяване отдясно, слабост':
                                        return 'скъсяване отдясно';
                                    case 'скъсяване отдясно, слабост отляво':
                                        return 'скъсяване отдясно';
                                    case 'скъсяване отдясно, слабост повече отляво':
                                        return 'скъсяване отдясно';
                                    case 'скъсяване отдясно, слабост отдясно':
                                        return 'скъсяване отдясно';
                                    case 'скъсяване отдясно, слабост повече отдясно':
                                        return 'скъсяване отдясно';

                                    case 'скъсяване отляво':
                                        return 'скъсяване отляво';
                                    case 'скъсяване отляво, слабост':
                                        return 'скъсяване отляво';
                                    case 'скъсяване отляво, слабост отдясно':
                                        return 'скъсяване отляво';
                                    case 'скъсяване отляво, слабост повече отдясно':
                                        return 'скъсяване отляво';
                                    case 'скъсяване отляво, слабост отляво':
                                        return 'скъсяване отляво';
                                    case 'скъсяване отляво, слабост повече отляво':
                                        return 'скъсяване отляво';

                                    case 'скъсяване повече отляво':
                                        return 'скъсяване повече отляво';
                                    case 'скъсяване повече отляво, слабост':
                                        return 'скъсяване повече отляво';
                                    case 'скъсяване повече отляво, слабост отляво':
                                        return 'скъсяване повече отляво';
                                    case 'скъсяване повече отляво, слабост повече отляво':
                                        return 'скъсяване повече отляво';
                                    case 'скъсяване повече отляво, слабост отдясно':
                                        return 'скъсяване повече отляво';
                                    case 'скъсяване повече отляво, слабост повече отдясно':
                                        return 'скъсяване повече отляво';

                                    case 'скъсяване повече отдясно':
                                        return 'скъсяване повече отдясно';
                                    case 'скъсяване повече отдясно, слабост':
                                        return 'скъсяване повече отдясно';
                                    case 'скъсяване повече отдясно, слабост отляво':
                                        return 'скъсяване повече отдясно';
                                    case 'скъсяване повече отдясно, слабост повече отляво':
                                        return 'скъсяване повече отдясно';
                                    case 'скъсяване повече отдясно, слабост отдясно':
                                        return 'скъсяване повече отдясно';
                                    case 'скъсяване повече отдясно, слабост повече отдясно':
                                        return 'скъсяване повече отдясно';
                                    default:
                                        return null;
                                }
                            })()
                        }
                    </div>
                    {/*Подбедрица*/}
                    <div className='control-single-line'><label>Подбедрица:</label>
                        {(() => {
                            const forceLeft = props.data.values.forcemetry?.shank.left;
                            const forceRight = props.data.values.forcemetry?.shank.right;
                            const centLeft = props.data.values.centimetrics.shank.left;
                            const centRight = props.data.values.centimetrics.shank.right;
                            const muscleEval = musclesEvaluation(
                                '',
                                'флексия',
                                0,
                                26,
                                props.data.values.goniometry.ankle.left.flexion,
                                props.data.values.goniometry.ankle.right.flexion
                            );
                            const shorteningResult = musclesEvaluationShortening('', 'флексия', 0, 26, props.data.values.goniometry.ankle.left.flexion, props.data.values.goniometry.ankle.right.flexion);

                            return (forceLeft === forceRight || (!forceLeft && !forceRight))
                                ? (centLeft === centRight || (!centLeft && !centRight))
                                    ? (!muscleEval ?
                                        ((!forceLeft && !forceRight) && (!centLeft && !centRight) ? '' : 'в норма') :
                                        muscleEval)
                                    : (muscleEval)
                                        ? musclesEvaluationCentimetrics(
                                            centLeft,
                                            centRight,
                                            props.data.values.goniometry.ankle.left.flexion,
                                            props.data.values.goniometry.ankle.right.flexion,
                                            'флексия',
                                            0,
                                            26
                                        )
                                        : centLeft < centRight
                                            ? 'слабост отляво'
                                            : centLeft > centRight
                                                ? 'слабост отдясно'
                                                : ''
                                : (
                                    <>
                                        {shorteningResult && `${shorteningResult}, `}
                                        {forceLeft < forceRight ? 'слабост отляво' : 'слабост отдясно'}
                                    </>
                                );
                        })()}
                    </div>
                    {/*Тибиалис*/}
                    <div className='control-single-line'><label>Тибиалис:</label>
                        {(props.data.values.forcemetry?.tibialis.left &&
                            props.data.values.forcemetry.tibialis.left !== '' &&
                            props.data.values.forcemetry?.tibialis.right &&
                            props.data.values.forcemetry.tibialis.right !== '') ? (
                            (() => {
                                const leftForce = props.data.values.forcemetry?.tibialis.left;
                                const rightForce = props.data.values.forcemetry?.tibialis.right;
                                const shorteningResult = musclesEvaluationShortening('', 'екстензия', 0, 48, props.data.values.goniometry.ankle.left.extension, props.data.values.goniometry.ankle.right.extension);

                                return (
                                    <>
                                        {shorteningResult && shorteningResult}
                                        {(shorteningResult && leftForce !== rightForce) && ', '}
                                        {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                    </>
                                );
                            })()
                        ) : (
                            musclesEvaluation('', 'екстензия', 0, 48, props.data.values.goniometry.ankle.left.extension, props.data.values.goniometry.ankle.right.extension)
                        )}
                    </div>
                    {/*Extensor Digitorium Longus*/}
                    <div className='control-single-line'><label>Extensor Digitorium Longus:</label>
                        {(props.data.values.forcemetry?.tibialis.left &&
                            props.data.values.forcemetry.tibialis.left !== '' &&
                            props.data.values.forcemetry?.tibialis.right &&
                            props.data.values.forcemetry.tibialis.right !== '') ? (
                            (() => {
                                const leftForce = props.data.values.forcemetry?.tibialis.left;
                                const rightForce = props.data.values.forcemetry?.tibialis.right;
                                const shorteningResult = musclesEvaluationShortening('', 'екстензия', 0, 48, props.data.values.goniometry.ankle.left.extension, props.data.values.goniometry.ankle.right.extension);

                                return (
                                    <>
                                        {shorteningResult && shorteningResult}
                                        {(shorteningResult && leftForce !== rightForce) && ', '}
                                        {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                    </>
                                );
                            })()
                        ) : (
                            musclesEvaluation('', 'екстензия', 0, 48, props.data.values.goniometry.ankle.left.extension, props.data.values.goniometry.ankle.right.extension)
                        )}
                    </div>
                    {/*Peroneus Longus*/}
                    <div className='control-single-line'><label>Peroneus Longus:</label>
                        {(props.data.values.forcemetry?.tibialis.left &&
                            props.data.values.forcemetry.tibialis.left !== '' &&
                            props.data.values.forcemetry?.tibialis.right &&
                            props.data.values.forcemetry.tibialis.right !== '') ? (
                            (() => {
                                const leftForce = props.data.values.forcemetry?.tibialis.left;
                                const rightForce = props.data.values.forcemetry?.tibialis.right;
                                const shorteningResult = musclesEvaluationShortening('', 'екстензия', 0, 48, props.data.values.goniometry.ankle.left.extension, props.data.values.goniometry.ankle.right.extension);

                                return (
                                    <>
                                        {shorteningResult && shorteningResult}
                                        {(shorteningResult && leftForce !== rightForce) && ', '}
                                        {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                    </>
                                );
                            })()
                        ) : (
                            musclesEvaluation('', 'екстензия', 0, 48, props.data.values.goniometry.ankle.left.extension, props.data.values.goniometry.ankle.right.extension)
                        )}
                    </div>
                    {/*Абдуктори долни крайници*/}
                    <div className='control-single-line'><label>Абдуктори долни крайници:</label>
                        {(props.data.values.forcemetry?.abductors.left &&
                            props.data.values.forcemetry.abductors.left !== '' &&
                            props.data.values.forcemetry?.abductors.right &&
                            props.data.values.forcemetry.abductors.right !== '') ? (
                            (() => {
                                const leftForce = props.data.values.forcemetry?.abductors.left;
                                const rightForce = props.data.values.forcemetry?.abductors.right;
                                const shorteningResult = musclesEvaluationShortening('', 'аддукция', 0, 30, props.data.values.goniometry.hip.left.adduction, props.data.values.goniometry.hip.right.adduction);

                                return (
                                    <>
                                        {shorteningResult && shorteningResult}
                                        {(shorteningResult && leftForce !== rightForce) && ', '}
                                        {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                    </>
                                );
                            })()
                        ) : (
                            musclesEvaluation('', 'аддукция', 0, 30, props.data.values.goniometry.hip.left.adduction, props.data.values.goniometry.hip.right.adduction)
                        )}
                    </div>
                    {/*Аддуктори долни крайници*/}
                    <div className='control-single-line'><label>Аддуктори долни крайници:</label>
                        {(props.data.values.forcemetry?.adductors.left &&
                            props.data.values.forcemetry.adductors.left !== '' &&
                            props.data.values.forcemetry?.adductors.right &&
                            props.data.values.forcemetry.adductors.right !== '') ? (
                            (() => {
                                const leftForce = props.data.values.forcemetry?.adductors.left;
                                const rightForce = props.data.values.forcemetry?.adductors.right;
                                const shorteningResult = musclesEvaluationShortening('', 'абдукция', 0, 45, props.data.values.goniometry.hip.left.abduction, props.data.values.goniometry.hip.right.abduction);

                                return (
                                    <>
                                        {shorteningResult && shorteningResult}
                                        {(shorteningResult && leftForce !== rightForce) && ', '}
                                        {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                    </>
                                );
                            })()
                        ) : (
                            musclesEvaluation('', 'абдукция', 0, 45, props.data.values.goniometry.hip.left.abduction, props.data.values.goniometry.hip.right.abduction)
                        )}
                    </div>
                    {/*Четириглав бедрен мускул*/}
                    <div className='control-single-line'><label>Четириглав бедрен мускул:</label>
                        {(() => {
                            const forceLeft = props.data.values.forcemetry?.hip.left;
                            const forceRight = props.data.values.forcemetry?.hip.right;
                            const centLeft = props.data.values.centimetrics.hip.left;
                            const centRight = props.data.values.centimetrics.hip.right;
                            const muscleEval = musclesEvaluation(
                                '',
                                'екстензия',
                                0,
                                15,
                                props.data.values.goniometry.hip.left.extension,
                                props.data.values.goniometry.hip.right.extension
                            );
                            const shorteningResult = musclesEvaluationShortening('', 'екстензия', 0, 15, props.data.values.goniometry.hip.left.extension, props.data.values.goniometry.hip.right.extension);

                            return (forceLeft === forceRight || (!forceLeft && !forceRight))
                                ? (centLeft === centRight || (!centLeft && !centRight))
                                    ? (!muscleEval ?
                                        ((!forceLeft && !forceRight) && (!centLeft && !centRight) ? '' : 'в норма') :
                                        muscleEval)
                                    : (muscleEval)
                                        ? musclesEvaluationCentimetrics(
                                            centLeft,
                                            centRight,
                                            props.data.values.goniometry.hip.left.extension,
                                            props.data.values.goniometry.hip.right.extension,
                                            'екстензия',
                                            0,
                                            15
                                        )
                                        : centLeft < centRight
                                            ? 'слабост отляво'
                                            : centLeft > centRight
                                                ? 'слабост отдясно'
                                                : ''
                                : (
                                    <>
                                        {shorteningResult && `${shorteningResult}, `}
                                        {forceLeft < forceRight ? 'слабост отляво' : 'слабост отдясно'}
                                    </>
                                );
                        })()}
                    </div>
                    {/*Сухожилия и мускули на задно бедро*/}
                    <div className='control-single-line'><label>Сухожилия и мускули на задно бедро:</label>
                        {(() => {
                            const forceLeft = props.data.values.forcemetry?.backHip.left;
                            const forceRight = props.data.values.forcemetry?.backHip.right;
                            const centLeft = props.data.values.centimetrics.hip.left;
                            const centRight = props.data.values.centimetrics.hip.right;
                            const muscleEval = musclesEvaluation(
                                '',
                                'флексия',
                                0,
                                90,
                                props.data.values.goniometry.hip.left.flexionStrKnee,
                                props.data.values.goniometry.hip.right.flexionStrKnee
                            );
                            const shorteningResult = musclesEvaluationShortening('', 'флексия', 0, 90, props.data.values.goniometry.hip.left.flexionStrKnee, props.data.values.goniometry.hip.right.flexionStrKnee);

                            return (forceLeft === forceRight || (!forceLeft && !forceRight))
                                ? (centLeft === centRight || (!centLeft && !centRight))
                                    ? (!muscleEval ?
                                        ((!forceLeft && !forceRight) && (!centLeft && !centRight) ? '' : 'в норма') :
                                        muscleEval)
                                    : (muscleEval)
                                        ? musclesEvaluationCentimetrics(
                                            centLeft,
                                            centRight,
                                            props.data.values.goniometry.hip.left.flexionStrKnee,
                                            props.data.values.goniometry.hip.right.flexionStrKnee,
                                            'флексия',
                                            0,
                                            90
                                        )
                                        : centLeft < centRight
                                            ? 'слабост отляво'
                                            : centLeft > centRight
                                                ? 'слабост отдясно'
                                                : ''
                                : (
                                    <>
                                        {shorteningResult && `${shorteningResult}, `}
                                        {forceLeft < forceRight ? 'слабост отляво' : 'слабост отдясно'}
                                    </>
                                );
                        })()}
                    </div>
                    {/*Глутеус*/}
                    <div className='control-single-line'><label>Глутеус:</label>
                        {(() => {
                            const forceLeft = props.data.values.forcemetry?.gluteus.left;
                            const forceRight = props.data.values.forcemetry?.gluteus.right;
                            const centLeft = props.data.values.centimetrics.hip.left;
                            const centRight = props.data.values.centimetrics.hip.right;
                            const muscleEval = musclesEvaluation(
                                '',
                                'флексия',
                                0,
                                90,
                                props.data.values.goniometry.hip.left.flexionStrKnee,
                                props.data.values.goniometry.hip.right.flexionStrKnee
                            );
                            const shorteningResult = musclesEvaluationShortening('', 'флексия', 0, 90, props.data.values.goniometry.hip.left.flexionStrKnee, props.data.values.goniometry.hip.right.flexionStrKnee);

                            return (forceLeft === forceRight || (!forceLeft && !forceRight))
                                ? (centLeft === centRight || (!centLeft && !centRight))
                                    ? (!muscleEval ?
                                        ((!forceLeft && !forceRight) && (!centLeft && !centRight) ? '' : 'в норма') :
                                        muscleEval)
                                    : (muscleEval)
                                        ? musclesEvaluationCentimetrics(
                                            centLeft,
                                            centRight,
                                            props.data.values.goniometry.hip.left.flexionStrKnee,
                                            props.data.values.goniometry.hip.right.flexionStrKnee,
                                            'флексия',
                                            0,
                                            90
                                        )
                                        : centLeft < centRight
                                            ? 'слабост отляво'
                                            : centLeft > centRight
                                                ? 'слабост отдясно'
                                                : ''
                                : (
                                    <>
                                        {shorteningResult && `${shorteningResult}, `}
                                        {forceLeft < forceRight ? 'слабост отляво' : 'слабост отдясно'}
                                    </>
                                );
                        })()}
                    </div>
                    {/*Бицепс*/}
                    <div className='control-single-line'><label>Бицепс:</label>
                        {(props.data.values.forcemetry?.biceps.left &&
                            props.data.values.forcemetry.biceps.left !== '' &&
                            props.data.values.forcemetry?.biceps.right &&
                            props.data.values.forcemetry.biceps.right !== '') ? (
                            (() => {
                                const leftForce = props.data.values.forcemetry?.biceps.left;
                                const rightForce = props.data.values.forcemetry?.biceps.right;
                                const shorteningResult = musclesEvaluationShortening('', 'екстензия', 0, 0, props.data.values.goniometry.elbow.left.extension, props.data.values.goniometry.elbow.right.extension);

                                return (
                                    <>
                                        {shorteningResult && shorteningResult}
                                        {(shorteningResult && leftForce !== rightForce) && ', '}
                                        {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                    </>
                                );
                            })()
                        ) : (
                            musclesEvaluation('', 'екстензия', 0, 0, props.data.values.goniometry.elbow.left.extension, props.data.values.goniometry.elbow.right.extension)
                        )}
                    </div>
                    {/*Трицепс*/}
                    <div className='control-single-line'><label>Трицепс:</label>
                        {(props.data.values.forcemetry?.triceps.left &&
                            props.data.values.forcemetry.triceps.left !== '' &&
                            props.data.values.forcemetry?.triceps.right &&
                            props.data.values.forcemetry.triceps.right !== '') ? (
                            (() => {
                                const leftForce = props.data.values.forcemetry?.triceps.left;
                                const rightForce = props.data.values.forcemetry?.triceps.right;
                                const shorteningResult = musclesEvaluationShortening('', 'флексия', 0, 145, props.data.values.goniometry.elbow.left.flexion, props.data.values.goniometry.elbow.right.flexion);

                                return (
                                    <>
                                        {shorteningResult && shorteningResult}
                                        {(shorteningResult && leftForce !== rightForce) && ', '}
                                        {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                    </>
                                );
                            })()
                        ) : (
                            musclesEvaluation('', 'флексия', 0, 145, props.data.values.goniometry.elbow.left.flexion, props.data.values.goniometry.elbow.right.flexion)
                        )}
                    </div>
                    {/*Флексори на предмишни мускули*/}
                    <div className='control-single-line'><label>Флексори на предмишни мускули:</label>
                        {(props.data.values.forcemetry?.wristFlex.left &&
                            props.data.values.forcemetry.wristFlex.left !== '' &&
                            props.data.values.forcemetry?.wristFlex.right &&
                            props.data.values.forcemetry.wristFlex.right !== '') ? (
                            (() => {
                                const leftForce = props.data.values.forcemetry?.wristFlex.left;
                                const rightForce = props.data.values.forcemetry?.wristFlex.right;
                                const shorteningResult = musclesEvaluationShortening('', 'екстензия', 0, 70, props.data.values.goniometry.wrist.left.extension, props.data.values.goniometry.wrist.right.extension);

                                return (
                                    <>
                                        {shorteningResult && shorteningResult}
                                        {(shorteningResult && leftForce !== rightForce) && ', '}
                                        {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                    </>
                                );
                            })()
                        ) : (
                            musclesEvaluation('', 'екстензия', 0, 70, props.data.values.goniometry.wrist.left.extension, props.data.values.goniometry.wrist.right.extension)
                        )}
                    </div>
                    {/*Екстензори на предмишни мускули*/}
                    <div className='control-single-line'><label>Екстензори на предмишни мускули:</label>
                        {(props.data.values.forcemetry?.wristExt.left &&
                            props.data.values.forcemetry.wristExt.left !== '' &&
                            props.data.values.forcemetry?.wristExt.right &&
                            props.data.values.forcemetry.wristExt.right !== '') ? (
                            (() => {
                                const leftForce = props.data.values.forcemetry?.wristExt.left;
                                const rightForce = props.data.values.forcemetry?.wristExt.right;
                                const shorteningResult = musclesEvaluationShortening('', 'флексия', 0, 90, props.data.values.goniometry.wrist.left.flexion, props.data.values.goniometry.wrist.right.flexion);

                                return (
                                    <>
                                        {shorteningResult && shorteningResult}
                                        {(shorteningResult && leftForce !== rightForce) && ', '}
                                        {leftForce < rightForce ? 'слабост отляво' : (leftForce > rightForce ? 'слабост отдясно' : (shorteningResult === '' ? 'в норма' : ''))}
                                    </>
                                );
                            })()
                        ) : (
                            musclesEvaluation('', 'флексия', 0, 90, props.data.values.goniometry.wrist.left.flexion, props.data.values.goniometry.wrist.right.flexion)
                        )}
                    </div>
                </div>
                <div>
                    <div><img src={musclesFront} className="muscles-img view" /></div>
                    <div><img src={musclesBack} className="muscles-img view" /></div>
                </div>
            </div>
            <div className="page-break" />
            <h2>Сантиметрия</h2>
            <div className='control multi-inp cols wider'>
                <div className='card'>
                    <img src={hip} className="control-img" />
                    <div className='card-mini'>
                        <h3>Бедро:</h3>
                        <label>Ляво:</label> {props.data.values.centimetrics.hip.left && <span className={props.data.values.centimetrics.hip.left < props.data.values.centimetrics.hip.right ? 'alert-def' : ''}>{props.data.values.centimetrics.hip.left} см</span>}
                        <label>Дясно:</label> {props.data.values.centimetrics.hip.right && <span className={props.data.values.centimetrics.hip.left > props.data.values.centimetrics.hip.right ? 'alert-def' : ''}>{props.data.values.centimetrics.hip.right} см</span>}
                    </div>
                </div>
                <div className='card'>
                    <img src={shank} className="control-img" />
                    <div className='card-mini'>
                        <h3>Подбедрици:</h3>
                        <label>Лява:</label> {props.data.values.centimetrics.shank.left && <span className={props.data.values.centimetrics.shank.left < props.data.values.centimetrics.shank.right ? 'alert-def' : ''}>{props.data.values.centimetrics.shank.left} см</span>}
                        <label>Дясна:</label> {props.data.values.centimetrics.shank.right && <span className={props.data.values.centimetrics.shank.left > props.data.values.centimetrics.shank.right ? 'alert-def' : ''}>{props.data.values.centimetrics.shank.right} см</span>}
                    </div>
                </div>
                <div className='card'>
                    <img src={shoulders} className="control-img" />
                    <div className='card-mini'>
                        <h3>Раменен пояс:</h3>
                        {props.data.values.centimetrics.shoulders && <>{props.data.values.centimetrics.shoulders} см</>}
                    </div>
                </div>
                <div className='card'>
                    <img src={neck_measurement} className="control-img" />
                    <div className='card-mini'>
                        <h3>Врат:</h3>
                        {props.data.values.centimetrics.neck && <>{props.data.values.centimetrics.neck} см</>}
                    </div>
                </div>
                <div className='card'>
                    <img src={abdomen} className="control-img" />
                    <div className='card-mini'>
                        <h3>Корем:</h3>
                        {props.data.values.centimetrics.abdomen && <>{props.data.values.centimetrics.abdomen} см</>}
                    </div>
                </div>
                <div className='card'>
                    <img src={chest} className="control-img" />
                    <div className='card-mini'>
                        <h3>Гърди:</h3>
                        {props.data.values.centimetrics.chest && <>{props.data.values.centimetrics.chest} см</>}
                    </div>
                </div>
                <div className='card'>
                    <img src={biceps} className="control-img" />
                    <div className='card-mini'>
                        <h3>Бицепс:</h3>
                        <label>Ляв:</label> {props.data.values.centimetrics.biceps.left && <span className={props.data.values.centimetrics.biceps.left < props.data.values.centimetrics.biceps.right ? 'alert-def' : ''}>{props.data.values.centimetrics.biceps.left} см</span>}
                        <label>Десен:</label> {props.data.values.centimetrics.biceps.right && <span className={props.data.values.centimetrics.biceps.left > props.data.values.centimetrics.biceps.right ? 'alert-def' : ''}>{props.data.values.centimetrics.biceps.right} см</span>}
                    </div>
                </div>
                <div className='card'>
                    <img src={forearm} className="control-img" />
                    <div className='card-mini'>
                        <h3>Предмишница:</h3>
                        <label>Лява:</label> {props.data.values.centimetrics.forearm.left && <span className={props.data.values.centimetrics.forearm.left < props.data.values.centimetrics.forearm.right ? 'alert-def' : ''}>{props.data.values.centimetrics.forearm.left} см</span>}
                        <label>Дясна:</label> {props.data.values.centimetrics.forearm.right && <span className={props.data.values.centimetrics.forearm.left > props.data.values.centimetrics.forearm.right ? 'alert-def' : ''}>{props.data.values.centimetrics.forearm.right} см</span>}
                    </div>
                </div>
                <div className='card'></div>
            </div>
            <div className="important-info-wrapper">Внимание: Сантиметрията може да бъде показател за състоянието на периферната нервна система по крайниците, така също за спортисти и за хора, спазващи режим на хранене.</div>
            <div className="page-break" />
            <h2>Измервания на дължината на костите на крайниците</h2>
            <div className='multi-inp wider top'>
                <div style={{padding:'1em'}}>
                    <h3>Бедрена кост (Femur):</h3>
                    <div className='control multi-inp'>
                        <p className='control'><label>Ляв крак:</label>{props.data.values.boneLength.femur.left && <span className={props.data.values.boneLength.femur.left < props.data.values.boneLength.femur.right ? 'alert-def' : ''}>{props.data.values.boneLength.femur.left} см</span>}</p>
                        <div><img src={femur} className="control-img" /></div>
                        <p className='control'><label>Десен крак:</label>{props.data.values.boneLength.femur.right && <span className={props.data.values.boneLength.femur.right < props.data.values.boneLength.femur.left ? 'alert-def' : ''}>{props.data.values.boneLength.femur.right} см</span>}</p>
                    </div>
                    <h3>Голям и малък пищял (Tibia):</h3>
                    <div className='control multi-inp'>
                        <p className='control'><label>Ляв крак:</label>{props.data.values.boneLength.tibia.left && <span className={props.data.values.boneLength.tibia.left < props.data.values.boneLength.tibia.right ? 'alert-def' : ''}>{props.data.values.boneLength.tibia.left} см</span>}</p>
                        <div><img src={tibia} className="control-img" /></div>
                        <p className='control'><label>Десен крак:</label>{props.data.values.boneLength.tibia.right && <span className={props.data.values.boneLength.tibia.right < props.data.values.boneLength.tibia.left ? 'alert-def' : ''}>{props.data.values.boneLength.tibia.right} см</span>}</p>
                    </div>
                </div>
                <div style={{padding:'1em'}}>
                    <h3>Раменна кост (Humerus):</h3>
                    <div className='control multi-inp'>
                        <p className='control'><label>Лява ръка:</label>{props.data.values.boneLength.humerus.left && <span className={props.data.values.boneLength.humerus.left < props.data.values.boneLength.humerus.right ? 'alert-def' : ''}>{props.data.values.boneLength.humerus.left} см</span>}</p>
                        <div><img src={humerus} className="control-img" /></div>
                        <p className='control'><label>Дясна ръка:</label>{props.data.values.boneLength.humerus.right && <span className={props.data.values.boneLength.humerus.right < props.data.values.boneLength.humerus.left ? 'alert-def' : ''}>{props.data.values.boneLength.humerus.right} см</span>}</p>
                    </div>
                    <h3>Лакътна и лъчева кост (Radius):</h3>
                    <div className='control multi-inp'>
                        <p className='control'><label>Лява ръка:</label>{props.data.values.boneLength.radius.left && <span className={props.data.values.boneLength.radius.left < props.data.values.boneLength.radius.right ? 'alert-def' : ''}>{props.data.values.boneLength.radius.left} см</span>}</p>
                        <div><img src={radius} className="control-img" /></div>
                        <p className='control'><label>Дясна ръка:</label>{props.data.values.boneLength.radius.right && <span className={props.data.values.boneLength.radius.right < props.data.values.boneLength.radius.left ? 'alert-def' : ''}>{props.data.values.boneLength.radius.right} см</span>}</p>
                    </div>
                </div>
            </div>
            <div className="page-break" />
            {props.data.values.thermography && <h2>Термография</h2>}
            <div className='multi-inp wider top therm-container'>
                {props.data.values.thermography?.spine && <ThermographyCard title="Гръбначен стълб" degreesMin={props.data.values.thermography.spine?.degreesMin || ""} degreesMax={props.data.values.thermography.spine?.degreesMax || ""} note={props.data.values.thermography.spine.note} image={props.data.values.thermography.spine.image} />}
                {props.data.values.thermography?.neck && <ThermographyCard title="Шиен дял" degreesMin={props.data.values.thermography.neck?.degreesMin || ""} degreesMax={props.data.values.thermography.neck?.degreesMax || ""} note={props.data.values.thermography.neck.note} image={props.data.values.thermography.neck.image} />}
                {props.data.values.thermography?.thoracic && <ThermographyCard title="Гръден дял" degreesMin={props.data.values.thermography.thoracic?.degreesMin || ""} degreesMax={props.data.values.thermography.thoracic?.degreesMax || ""} note={props.data.values.thermography.thoracic.note} image={props.data.values.thermography.thoracic.image} />}
                {props.data.values.thermography?.lumbar && <ThermographyCard title="Лумбален дял" degreesMin={props.data.values.thermography.lumbar?.degreesMin || ""} degreesMax={props.data.values.thermography.lumbar?.degreesMax || ""} note={props.data.values.thermography.lumbar.note} image={props.data.values.thermography.lumbar.image} />}
                <div className="page-break" />
                {props.data.values.thermography?.sacral && <ThermographyCard title="Сакрален дял" degreesMin={props.data.values.thermography.sacral?.degreesMin || ""} degreesMax={props.data.values.thermography.sacral?.degreesMax || ""} note={props.data.values.thermography.sacral.note} image={props.data.values.thermography.sacral.image} />}
                {props.data.values.thermography?.rArmLat && <ThermographyCard title="Десен горен крайник (лат.)" degreesMin={props.data.values.thermography.rArmLat?.degreesMin || ""} degreesMax={props.data.values.thermography.rArmLat?.degreesMax || ""} note={props.data.values.thermography.rArmLat.note} image={props.data.values.thermography.rArmLat.image} />}
                {props.data.values.thermography?.rArmMed && <ThermographyCard title="Десен горен крайник (мед.)" degreesMin={props.data.values.thermography.rArmMed?.degreesMin || ""} degreesMax={props.data.values.thermography.rArmMed?.degreesMax || ""} note={props.data.values.thermography.rArmMed.note} image={props.data.values.thermography.rArmMed.image} />}
                {props.data.values.thermography?.lArmLat && <ThermographyCard title="Ляв горен крайник (лат.)" degreesMin={props.data.values.thermography.lArmLat?.degreesMin || ""} degreesMax={props.data.values.thermography.lArmLat?.degreesMax || ""} note={props.data.values.thermography.lArmLat.note} image={props.data.values.thermography.lArmLat.image} />}
                <div className="page-break" />
                {props.data.values.thermography?.lArmMed && <ThermographyCard title="Ляв горен крайник (мед.)" degreesMin={props.data.values.thermography.lArmMed?.degreesMin || ""} degreesMax={props.data.values.thermography.lArmMed?.degreesMax || ""} note={props.data.values.thermography.lArmMed.note} image={props.data.values.thermography.lArmMed.image} />}
                {props.data.values.thermography?.elbowLat && <ThermographyCard title="Лакътна става (лат.)" degreesMin={props.data.values.thermography.elbowLat?.degreesMin || ""} degreesMax={props.data.values.thermography.elbowLat?.degreesMax || ""} note={props.data.values.thermography.elbowLat.note} image={props.data.values.thermography.elbowLat.image} />}
                {props.data.values.thermography?.elbowMed && <ThermographyCard title="Лакътна става (мед.)" degreesMin={props.data.values.thermography.elbowMed?.degreesMin || ""} degreesMax={props.data.values.thermography.elbowMed?.degreesMax || ""} note={props.data.values.thermography.elbowMed.note} image={props.data.values.thermography.elbowMed.image} />}
                {props.data.values.thermography?.wristSup && <ThermographyCard title="Киткена става (супинация)" degreesMin={props.data.values.thermography.wristSup?.degreesMin || ""} degreesMax={props.data.values.thermography.wristSup?.degreesMax || ""} note={props.data.values.thermography.wristSup.note} image={props.data.values.thermography.wristSup.image} />}
                <div className="page-break" />
                {props.data.values.thermography?.wristPro && <ThermographyCard title="Киткена става (пронация)" degreesMin={props.data.values.thermography.wristPro?.degreesMin || ""} degreesMax={props.data.values.thermography.wristPro?.degreesMax || ""} note={props.data.values.thermography.wristPro.note} image={props.data.values.thermography.wristPro.image} />}
                {props.data.values.thermography?.shoulderFront && <ThermographyCard title="Раменна става (предно)" degreesMin={props.data.values.thermography.shoulderFront?.degreesMin || ""} degreesMax={props.data.values.thermography.shoulderFront?.degreesMax || ""} note={props.data.values.thermography.shoulderFront.note} image={props.data.values.thermography.shoulderFront.image} />}
                {props.data.values.thermography?.shoulderBack && <ThermographyCard title="Раменна става (задно)" degreesMin={props.data.values.thermography.shoulderBack?.degreesMin || ""} degreesMax={props.data.values.thermography.shoulderBack?.degreesMax || ""} note={props.data.values.thermography.shoulderBack.note} image={props.data.values.thermography.shoulderBack.image} />}
                {props.data.values.thermography?.rLegFront && <ThermographyCard title="Десен долен крайник (отпред)" degreesMin={props.data.values.thermography.rLegFront?.degreesMin || ""} degreesMax={props.data.values.thermography.rLegFront?.degreesMax || ""} note={props.data.values.thermography.rLegFront.note} image={props.data.values.thermography.rLegFront.image} />}
                <div className="page-break" />
                {props.data.values.thermography?.lLegFront && <ThermographyCard title="Ляв долен крайник (отпред)" degreesMin={props.data.values.thermography.lLegFront?.degreesMin || ""} degreesMax={props.data.values.thermography.lLegFront?.degreesMax || ""} note={props.data.values.thermography.lLegFront.note} image={props.data.values.thermography.lLegFront.image} />}
                {props.data.values.thermography?.kneeFront && <ThermographyCard title="Колянна става (отпред)" degreesMin={props.data.values.thermography.kneeFront?.degreesMin || ""} degreesMax={props.data.values.thermography.kneeFront?.degreesMax || ""} note={props.data.values.thermography.kneeFront.note} image={props.data.values.thermography.kneeFront.image} />}
                {props.data.values.thermography?.hip && <ThermographyCard title="ТБС" degreesMin={props.data.values.thermography.hip?.degreesMin || ""} degreesMax={props.data.values.thermography.hip?.degreesMax || ""} note={props.data.values.thermography?.hip.note} image={props.data.values.thermography.hip.image} />}
                {props.data.values.thermography?.ankle && <ThermographyCard title="Глезенна става" degreesMin={props.data.values.thermography.ankle?.degreesMin || ""} degreesMax={props.data.values.thermography.ankle?.degreesMax || ""} note={props.data.values.thermography.ankle.note} image={props.data.values.thermography.ankle.image} />}
                <div className="page-break" />
                {props.data.values.thermography?.rLegBack && <ThermographyCard title="Ляв долен крайник (отзад)" degreesMin={props.data.values.thermography?.rLegBack.degreesMin || ""} degreesMax={props.data.values.thermography.rLegBack?.degreesMax || ""} note={props.data.values.thermography.rLegBack.note} image={props.data.values.thermography.rLegBack.image} />}
                {props.data.values.thermography?.lLegBack && <ThermographyCard title="Десен долен крайник (отзад)" degreesMin={props.data.values.thermography?.lLegBack.degreesMin || ""} degreesMax={props.data.values.thermography.lLegBack?.degreesMax || ""} note={props.data.values.thermography.lLegBack.note} image={props.data.values.thermography.lLegBack.image} />}
                {props.data.values.thermography?.kneeBack && <ThermographyCard title="Колянна става (сгъвка)" degreesMin={props.data.values.thermography.kneeBack?.degreesMin || ""} degreesMax={props.data.values.thermography.kneeBack?.degreesMax || ""} note={props.data.values.thermography.kneeBack.note} image={props.data.values.thermography.kneeBack.image} />}
                {props.data.values.thermography?.achilles && <ThermographyCard title="Ахилесово сухожилие" degreesMin={props.data.values.thermography.achilles?.degreesMin || ""} degreesMax={props.data.values.thermography.achilles?.degreesMax || ""} note={props.data.values.thermography.achilles.note} image={props.data.values.thermography.achilles.image} />}
            </div>
            {props.data.values.thermography && <div className="page-break" />}

            <h2>Тест за чувствителност</h2>
            <div className="svg-container">
                <svg width="100%" height="100%" viewBox="0 0 229 800">
                    {Object.entries(rightFront).map(([part, { d, fill }]) => (
                        <path key={part} d={d} fill={props.data.values.sensitivity?.right?.[part] || "#b2b2b2"} />
                    ))}
                </svg>
            </div>
            <div className="svg-container">
                <svg width="100%" height="100%" viewBox="0 0 229 800">
                    {Object.entries(leftFront).map(([part, { d, fill }]) => (
                        <path key={part} d={d} fill={props.data.values.sensitivity?.left?.[part] || "#b2b2b2"} />
                    ))}
                </svg>
            </div>
            <div className="svg-container">
                <svg width="100%" height="100%" viewBox="0 0 229 800">
                    {Object.entries(leftBack).map(([part, { d, fill }]) => (
                        <path key={part} d={d} fill={props.data.values.sensitivity?.left?.[part] || "#b2b2b2"} />
                    ))}
                </svg>
            </div>
            <div className="svg-container">
                <svg width="100%" height="100%" viewBox="0 0 229 800">
                    {Object.entries(rightBack).map(([part, { d, fill }]) => (
                        <path key={part} d={d} fill={props.data.values.sensitivity?.right?.[part] || "#b2b2b2"} />
                    ))}
                </svg>
            </div>
            <h3 className='recommendations-h'><label>Бележка:</label></h3>
            <p className='centered-print recommendations'>{props.data.values.sensitivity?.note}</p>

            <div className="important-info-wrapper">Диагностиката не се използва като конкретна диагноза, а служи за следене общото състояние на опорно-двигателния апарат във времето, и за взимането на правилни действия с цел балансиране на костна и мускулна система.</div>
        </section>
    );
  });
  
  export default DiagnosticsSheetData;