import React from "react";

const ThermographyInput = (props) => {

    return (
        <div style={{margin: '0 0 1em 0'}}>
            <h3>{props.title}</h3>
            <p className='control'>
                <label>Градуси:</label>
            </p>
            <div className='control multi-inp'>
                <p className='control short'>
                    <label htmlFor={props.degreesMin}>Минимални:</label>
                    <input
                        {...props.register(props.degreesMin, {valueAsNumber: true})}
                        type="number"
                        step="any"
                    />
                </p>
                <p className='control short'>
                    <label htmlFor='triceps_right'>Максимални:</label>
                    <input
                        {...props.register(props.degreesMax, {valueAsNumber: true})}
                        type="number"
                        step="any"
                    />
                </p>
            </div>
            <input type="hidden" {...props.register(props.imageNew)} />
            <p className='control' style={{marginBottom:'1em'}}>
                <label htmlFor={props.image}>Снимка:</label>
                <input
                    {...props.register(props.image)}
                    name={props.image}
                    type="file"
                    accept=".jpg,.jpeg,.png"
                    // onChange={e => handleFileRead(e)}
                    onChange={e => props.handleFileRead(e, props.image, props.imageNew, props.setField)}
                />
                <img src={props.thumb} style={{maxWidth: '250px', maxHeight: '250px', margin: '1em 0'}} />
            </p>
            <p className='control'>
                <label>Бележка:</label>
                <input
                    {...props.register(props.note)}
                />
            </p>
        </div>
    );
};

export default ThermographyInput;